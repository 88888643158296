import React from 'react';
import { FileExport } from './sharedComponents/common.types';
import { showSuccessNotification } from './notifications/SuccessNotification';

export const triggerBrowserDownload = (
    linkEl: React.RefObject<HTMLAnchorElement>,
    fileExport: FileExport | undefined,
    fallBackName: string,
) => {
    if (linkEl.current !== null && fileExport?.fileLink !== undefined) {
        linkEl.current.href = fileExport.fileLink;
        linkEl.current.download = fileExport.fileName ?? fallBackName;
        linkEl.current.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(fileExport.fileLink);
        }, 40000);
        showSuccessNotification('outboundPortal.notification.downloadExcel.success');
    }
};
