import { detectFeatureToggles, asBoolean } from '@rio-cloud/rio-uikit/urlFeatureToggles';
import { ToggleType } from '@rio-cloud/rio-uikit/utils/urlFeatureToggles';
import { addBreadcrumbToSentry } from '../setup/sentry';

// Define old toggles to clean up the local storage state
const cleanupStateForOldToggles: string[] = [];

export type ClientSideFeatureToggles = {
    enableConsoleTracing: boolean;
    useOidcContext: boolean;
    enableOidcLogging: boolean;
    useSigninCallback: boolean;
};

const featureToggleDefaults: ClientSideFeatureToggles = {
    enableConsoleTracing: false,
    useOidcContext: false,
    enableOidcLogging: false,
    useSigninCallback: false,
};

export const initializeFeatureToggles = () => {
    const featureToggleDefinitions: Record<
        keyof ClientSideFeatureToggles,
        (paramName: string) => ToggleType | undefined
    > = {
        enableConsoleTracing: asBoolean('ft_enable_console_tracing'),
        useOidcContext: asBoolean('ft_use_oidc_context'),
        enableOidcLogging: asBoolean('ft_enable_oidc_logging'),
        useSigninCallback: asBoolean('ft_use_signin_callback'),
    };

    const loadedFeatureToggles = detectFeatureToggles(featureToggleDefinitions, cleanupStateForOldToggles) as Record<
        keyof ClientSideFeatureToggles,
        ToggleType | undefined
    >;

    const booleanWithDefault = (key: keyof ClientSideFeatureToggles) =>
        (loadedFeatureToggles[key] as boolean) ?? featureToggleDefaults[key];

    clientSideFeatureToggles = {
        enableConsoleTracing: booleanWithDefault('enableConsoleTracing'),
        useOidcContext: booleanWithDefault('useOidcContext'),
        enableOidcLogging: booleanWithDefault('enableOidcLogging'),
        useSigninCallback: booleanWithDefault('useSigninCallback'),
    };

    addBreadcrumbToSentry({
        category: 'features',
        level: 'info',
        message: 'query parameter feature toggles',
        data: clientSideFeatureToggles,
    });
};

export let clientSideFeatureToggles: ClientSideFeatureToggles = featureToggleDefaults;
