import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { decodeResponse, validateResponse } from './responseUtil';
import { prepareHeaders } from './utils';
import { ServiceOrderPageCodec } from './types/serviceOrder/serviceOrdersApi.types';
import { mapToServiceOrders } from './mappers/serviceOrder/serviceOrdersMapper';
import { ServiceOrderPage, ServiceOrderStatus } from '../serviceOrders/ServiceOrders.types';
import { mapToApiServiceOrderCompletion } from './mappers/serviceOrder/completeServiceOrderMapper';
import { ServiceOrderCompletion } from '../serviceOrders/serviceOrderCompletion.types';
import {
    mapToServiceOrderExcelQueryParameters,
    mapToServiceOrderQueryParameters,
} from './mappers/serviceOrder/queryParamsMapper';
import { TableSort } from '../sharedComponents/table/SortedTableHeader';
import { ServiceOrdersTableColumns } from '../serviceOrders/table/ServiceOrdersTableColumns';
import { ServiceType } from '../serviceOrders/serviceType.types';
import { FileExport } from '../sharedComponents/common.types';
import { FILE_NAME_REGEX } from './transportOrderApi';

export const SERVICE_ORDERS_PATH = 'service-orders';
export const EXPORT_PATH = 'exports';
export const COMPLETE_SERVICE_ORDER_PATH = 'service-order-completions';
export const HAS_UNACKNOWLEDGED_CHANGES_PATH = 'has-unacknowledged-external-changes';

export const ARCHIVED_PATH = 'is-archived';

const CONTENT_TYPE_EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export type GetServiceOrdersQueryParams = {
    statuses?: ServiceOrderStatus[];
    serviceTypes?: ServiceType[];
    orderIssuedAtAfter?: Date;
    orderIssuedAtBefore?: Date;
    archived?: boolean;
    searchTerm?: string;
    sortBy?: TableSort<ServiceOrdersTableColumns>;
    limit: number;
};

export type GetServiceOrderExcelQueryParams = {
    statuses?: ServiceOrderStatus[];
    serviceTypes?: ServiceType[];
    orderIssuedAtAfter?: Date;
    orderIssuedAtBefore?: Date;
};

type PutCompleteServiceOrderProps = {
    serviceOrderCompletion: ServiceOrderCompletion;
};

type PutArchiveServiceOrderProps = {
    serviceOrderId: string;
};

type PutAcknowledgeServiceOrderChangesProps = {
    serviceOrderId: string;
};

const serviceOrderTag = 'ServiceOrder';

export const serviceOrderApi = createApi({
    reducerPath: 'serviceOrderApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.FINISHED_VEHICLES_SERVICE,
        prepareHeaders,
    }),
    tagTypes: [serviceOrderTag],
    endpoints: (builder) => ({
        getServiceOrders: builder.query<ServiceOrderPage, GetServiceOrdersQueryParams>({
            query: (queryParameters) => ({
                url: SERVICE_ORDERS_PATH,
                params: mapToServiceOrderQueryParameters(queryParameters),
                validateStatus: validateResponse(ServiceOrderPageCodec),
            }),
            transformResponse: decodeResponse(ServiceOrderPageCodec, mapToServiceOrders),
            providesTags: [serviceOrderTag],
        }),
        getServiceOrderExcel: builder.mutation<FileExport, GetServiceOrderExcelQueryParams>({
            query: (queryParameters) => ({
                url: `${SERVICE_ORDERS_PATH}/${EXPORT_PATH}`,
                params: mapToServiceOrderExcelQueryParameters(queryParameters),
                cache: 'no-cache',
                responseHandler: async (response): Promise<FileExport> => {
                    if (response.headers.get('content-type') === CONTENT_TYPE_EXCEL) {
                        return {
                            fileLink: window.URL.createObjectURL(await response.blob()),
                            fileName: response.headers.get('Content-Disposition')?.match(FILE_NAME_REGEX)?.at(1),
                        };
                    } else {
                        return response.json();
                    }
                },
            }),
        }),
        putCompleteServiceOrder: builder.mutation<void, PutCompleteServiceOrderProps>({
            query: (props) => ({
                url: `${COMPLETE_SERVICE_ORDER_PATH}/${props.serviceOrderCompletion.serviceOrderIdToBeCompleted}`,
                method: 'PUT',
                body: mapToApiServiceOrderCompletion(props.serviceOrderCompletion),
            }),
            invalidatesTags: [serviceOrderTag],
        }),
        putArchiveServiceOrder: builder.mutation<void, PutArchiveServiceOrderProps>({
            query: (props) => ({
                url: `${SERVICE_ORDERS_PATH}/${props.serviceOrderId}/${ARCHIVED_PATH}`,
                method: 'PUT',
                body: true,
            }),
            invalidatesTags: [serviceOrderTag],
        }),
        putAcknowledgeServiceOrderChanges: builder.mutation<void, PutAcknowledgeServiceOrderChangesProps>({
            query: (props) => ({
                url: `${SERVICE_ORDERS_PATH}/${props.serviceOrderId}/${HAS_UNACKNOWLEDGED_CHANGES_PATH}`,
                method: 'PUT',
                body: false,
            }),
            invalidatesTags: [serviceOrderTag],
        }),
    }),
});

export const {
    useGetServiceOrdersQuery,
    useGetServiceOrderExcelMutation,
    usePutCompleteServiceOrderMutation,
    usePutAcknowledgeServiceOrderChangesMutation,
    usePutArchiveServiceOrderMutation,
} = serviceOrderApi;
