import { ServiceOrderStatus } from '../../../serviceOrders/ServiceOrders.types';
import { ServiceType } from '../../../serviceOrders/serviceType.types';
import { ServiceOrdersTableColumns } from '../../../serviceOrders/table/ServiceOrdersTableColumns';
import { SortDirection, TableSort } from '../../../sharedComponents/table/SortedTableHeader';
import { GetServiceOrderExcelQueryParams, GetServiceOrdersQueryParams } from '../../serviceOrdersApi';
import {
    ApiServiceOrderExcelQueryParameter,
    ApiServiceOrderQueryParameter,
    ApiServiceOrderStatus,
    ServiceOrderApiSort,
} from '../../types/serviceOrder/serviceOrdersApi.types';
import { ApiServiceType } from '../../types/serviceOrder/serviceTypesApi.type';

const mapServiceOrderStatusToApiServiceOrderStatus = (status: ServiceOrderStatus): ApiServiceOrderStatus => {
    switch (status) {
        case ServiceOrderStatus.OPEN:
            return ApiServiceOrderStatus.OPEN;
        case ServiceOrderStatus.COMPLETED:
            return ApiServiceOrderStatus.COMPLETED;
        case ServiceOrderStatus.CANCELLED:
            return ApiServiceOrderStatus.CANCELLED;
    }
};

const mapServiceOrderStatusesToApiStatuses = (
    statusFilter: ServiceOrderStatus[],
): ApiServiceOrderStatus[] | undefined =>
    statusFilter.length === 0 ? undefined : statusFilter.map(mapServiceOrderStatusToApiServiceOrderStatus);

const mapServiceTypeToApiServiceType = (serviceType: ServiceType): ApiServiceType => {
    switch (serviceType) {
        case ServiceType.X01:
            return ApiServiceType.X01;
        case ServiceType.X02:
            return ApiServiceType.X02;
        case ServiceType.X03:
            return ApiServiceType.X03;
        case ServiceType.X04:
            return ApiServiceType.X04;
        case ServiceType.X05:
            return ApiServiceType.X05;
        case ServiceType.X06:
            return ApiServiceType.X06;
        case ServiceType.X07:
            return ApiServiceType.X07;
        case ServiceType.X08:
            return ApiServiceType.X08;
        case ServiceType.X09:
            return ApiServiceType.X09;
        case ServiceType.X10:
            return ApiServiceType.X10;
        case ServiceType.X11:
            return ApiServiceType.X11;
        case ServiceType.X12:
            return ApiServiceType.X12;
        case ServiceType.X13:
            return ApiServiceType.X13;
        case ServiceType.X14:
            return ApiServiceType.X14;
        case ServiceType.X15:
            return ApiServiceType.X15;
        case ServiceType.X16:
            return ApiServiceType.X16;
        case ServiceType.X17:
            return ApiServiceType.X17;
        case ServiceType.X18:
            return ApiServiceType.X18;
        case ServiceType.X19:
            return ApiServiceType.X19;
        case ServiceType.X20:
            return ApiServiceType.X20;
        case ServiceType.X21:
            return ApiServiceType.X21;
        case ServiceType.X22:
            return ApiServiceType.X22;
        case ServiceType.X23:
            return ApiServiceType.X23;
        case ServiceType.X24:
            return ApiServiceType.X24;
        case ServiceType.X25:
            return ApiServiceType.X25;
        case ServiceType.X26:
            return ApiServiceType.X26;
        case ServiceType.X27:
            return ApiServiceType.X27;
        case ServiceType.X99:
            return ApiServiceType.X99;
    }
};

const mapServiceTypesToApiServiceTypes = (serviceTypeFilter: ServiceType[]): ApiServiceType[] | undefined =>
    serviceTypeFilter.length === 0 ? undefined : serviceTypeFilter.map(mapServiceTypeToApiServiceType);

export const mapToServiceOrderApiSort = (sorting: TableSort<ServiceOrdersTableColumns>): ServiceOrderApiSort => {
    const sortDirection = sorting.dir;
    if (sortDirection === SortDirection.ASCENDING) {
        switch (sorting.column) {
            case ServiceOrdersTableColumns.SERVICE_CODE:
                return ServiceOrderApiSort.TYPE_ASC;
            case ServiceOrdersTableColumns.EXECUTION_DATE:
                return ServiceOrderApiSort.COMPLETED_AT_ASC;
            case ServiceOrdersTableColumns.STATUS:
                return ServiceOrderApiSort.STATUS_ASC;
            case ServiceOrdersTableColumns.ORDER_ISSUE_DATE:
                return ServiceOrderApiSort.ORDER_ISSUED_AT_ASC;
        }
    } else if (sortDirection === SortDirection.DESCENDING) {
        switch (sorting.column) {
            case ServiceOrdersTableColumns.SERVICE_CODE:
                return ServiceOrderApiSort.TYPE_DESC;
            case ServiceOrdersTableColumns.EXECUTION_DATE:
                return ServiceOrderApiSort.COMPLETED_AT_DESC;
            case ServiceOrdersTableColumns.STATUS:
                return ServiceOrderApiSort.STATUS_DES;
            case ServiceOrdersTableColumns.ORDER_ISSUE_DATE:
                return ServiceOrderApiSort.ORDER_ISSUED_AT_DESC;
        }
    }
    throw new Error('forgot to implement mapper for sorting or column');
};

export const mapToServiceOrderQueryParameters = (
    queryParameters: GetServiceOrdersQueryParams,
): ApiServiceOrderQueryParameter => ({
    statuses: queryParameters.statuses && mapServiceOrderStatusesToApiStatuses(queryParameters.statuses),
    service_types: queryParameters.serviceTypes && mapServiceTypesToApiServiceTypes(queryParameters.serviceTypes),
    order_issued_at_after: queryParameters.orderIssuedAtAfter?.toISOString(),
    order_issued_at_before: queryParameters.orderIssuedAtBefore?.toISOString(),
    archived: queryParameters.archived,
    q: queryParameters.searchTerm,
    sort: queryParameters.sortBy && mapToServiceOrderApiSort(queryParameters.sortBy),
    limit: queryParameters.limit,
});

export const mapToServiceOrderExcelQueryParameters = (
    queryParameters: GetServiceOrderExcelQueryParams,
): ApiServiceOrderExcelQueryParameter => ({
    statuses: queryParameters.statuses && mapServiceOrderStatusesToApiStatuses(queryParameters.statuses),
    service_types: queryParameters.serviceTypes && mapServiceTypesToApiServiceTypes(queryParameters.serviceTypes),
    order_issued_at_after: queryParameters.orderIssuedAtAfter?.toISOString(),
    order_issued_at_before: queryParameters.orderIssuedAtBefore?.toISOString(),
});
