import Button from '@rio-cloud/rio-uikit/Button';
import classNames from 'classnames';
import { FunctionComponent, PropsWithChildren, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { usePutArrivalCompletionMutation } from '../../../api/compoundManagementApi';
import { CompletedStatusAtInput } from './CompletedStatusAtInput';
import { runInBackgroundCallback } from '../../../../../configuration/setup/backgroundActions';
import { getStatusDateValidationMessageId } from './validateStatusDate';
import { showSuccessNotification } from '../../../notifications/SuccessNotification';
import { useAppDispatch } from '../../../../../configuration/setup/hooks';
import { compoundManagementSlice } from '../../../reducers/compoundManagementSlice';
import { handleQueryError } from '../../../notifications/ErrorNotification';

type ArrivalCompletedFormProps = PropsWithChildren<{
    timeZone: string;
    compoundVehicleId: string;
    disabled: boolean;
    arrivalCompletedAt: Date | undefined;
}>;

export const ArrivalCompletedForm: FunctionComponent<ArrivalCompletedFormProps> = ({
    timeZone,
    compoundVehicleId,
    disabled,
    children,
    arrivalCompletedAt: previousArrivalCompletedAt,
}) => {
    const dispatch = useAppDispatch();
    const [completeArrival, completeArrivalRequest] = usePutArrivalCompletionMutation();

    const rowClasses = 'display-flex gap-25 align-items-start';

    const [submitted, setSubmitted] = useState(false);
    const [arrivalCompletedAt, setArrivalCompletedAt] = useState<Date | string | undefined>(previousArrivalCompletedAt);

    const isUpdate = previousArrivalCompletedAt !== undefined;

    const handleConfirmArrival = runInBackgroundCallback(async () => {
        setSubmitted(true);

        const isStatusValid = getStatusDateValidationMessageId(arrivalCompletedAt) === undefined;

        if (isStatusValid) {
            try {
                await completeArrival({
                    compoundVehicleId,
                    arrivalStatusChanged: {
                        statusAt: arrivalCompletedAt!! as Date,
                    },
                }).unwrap();

                showSuccessNotification(
                    isUpdate
                        ? 'outboundOrderBook.compoundManagement.notification.inventoryVehicle.updateArrival.success'
                        : 'outboundOrderBook.compoundManagement.notification.incomingVehicle.confirmArrival.success',
                );
                dispatch(compoundManagementSlice.actions.deselectCompoundVehicle());
            } catch (error: unknown) {
                handleQueryError(error);
            }
        }
    });

    return (
        <>
            <div className={rowClasses}>
                <CompletedStatusAtInput
                    labelId="outboundOrderBook.compoundManagement.table.heading.arrivalDate"
                    timeZone={timeZone}
                    disabled={disabled}
                    statusDate={arrivalCompletedAt}
                    onChangeStatusDate={setArrivalCompletedAt}
                    validationErrorId={submitted ? getStatusDateValidationMessageId(arrivalCompletedAt) : undefined}
                />
                {children}
            </div>
            <hr />
            <div className="display-flex gap-25 justify-content-end">
                {isUpdate ? (
                    <UpdateArrivalButton
                        disabled={disabled}
                        isLoading={completeArrivalRequest.isLoading}
                        onClick={handleConfirmArrival}
                    />
                ) : (
                    <ConfirmArrivalButton
                        disabled={disabled}
                        isLoading={completeArrivalRequest.isLoading}
                        onClick={handleConfirmArrival}
                    />
                )}
            </div>
        </>
    );
};

const ConfirmArrivalButton: FunctionComponent<{ disabled: boolean; isLoading: boolean; onClick: () => void }> = ({
    disabled,
    isLoading,
    onClick,
}) => (
    <Button
        className={classNames(isLoading ? 'btn-loading' : '')}
        disabled={disabled || isLoading}
        iconName="rioglyph-load"
        bsStyle={Button.PRIMARY}
        onClick={onClick}
    >
        <FormattedMessage id="outboundPortal.compoundManagement.button.confirmArrival" />
    </Button>
);

const UpdateArrivalButton: FunctionComponent<{ disabled: boolean; isLoading: boolean; onClick: () => void }> = ({
    disabled,
    isLoading,
    onClick,
}) => (
    <Button
        className={classNames(isLoading ? 'btn-loading' : '')}
        disabled={disabled || isLoading}
        iconName="rioglyph-refresh"
        bsStyle={Button.DEFAULT}
        onClick={onClick}
    >
        <FormattedMessage id="outboundPortal.compoundManagement.button.updateArrival" />
    </Button>
);
