import { config } from '../config';
import { AuthProvider, type AuthProviderProps } from 'react-oidc-context';
import type { PropsWithChildren } from 'react';
import { AutoLogin } from './AutoLogin';
import { OAuthConfig } from '../configuration';
import { clientSideFeatureToggles } from '../configuration/featureToggle/clientSideFeatureToggles';
import { InMemoryWebStorage, Log as OidcLog, WebStorageStateStore } from 'oidc-client-ts';
import { trace } from '../configuration/setup/trace';
import { loginStorage } from '../configuration/login/storage';
import join from 'lodash/fp/join';

if (clientSideFeatureToggles.enableOidcLogging === true) {
    OidcLog.setLogger(console);
    OidcLog.setLevel(OidcLog.DEBUG);
}

export type RioAuthProviderProps = PropsWithChildren & {
    oauthConfig: OAuthConfig;
};

export const RioAuthProvider = ({ oauthConfig, children }: RioAuthProviderProps) =>
    config.login.mockAuthorization ? (
        children
    ) : (
        <AuthProvider {...oidcConfig}>
            <AutoLogin oauthConfig={oauthConfig}>{children}</AutoLogin>
        </AuthProvider>
    );

const onSigninCallback = (): void => {
    trace('oidcConfig: onSigninCallback');
    const searchParams = new URLSearchParams(window.location.search);
    // Remove the code and state parameters from the url when you are redirected from the authorize page.
    if (window.location.href.startsWith(config.login.redirectUri!!) && !searchParams.has('error')) {
        const initialRoute = loginStorage.getRoute();
        loginStorage.clear();
        if (initialRoute) {
            // only set the location if there is an initial route
            // history.replaceState has to be used prior to location.replace, otherwise the initial route
            // will only be appended to the existing path (e.g. /redirect?code=foo#/initialRoute)
            trace(`Restore initial route: #/${initialRoute}`);
            window.history.replaceState({}, document.title, '/');
            window.location.replace(`#/${initialRoute}`);
        } else {
            trace('Redirect without initial route detected. Set location to "/"');
            window.history.replaceState({}, document.title, '/');
        }
    }
};

const oidcConfig: AuthProviderProps = {
    authority: config.login.authority,
    client_id: config.login.clientId,
    loadUserInfo: false,
    redirect_uri: config.login.redirectUri,
    response_type: 'code',
    scope: join(' ', config.login.oauthScope),
    silent_redirect_uri: config.login.silentRedirectUri ?? config.login.redirectUri,
    post_logout_redirect_uri: config.postLogoutRedirectUri,
    includeIdTokenInSilentRenew: false,
    automaticSilentRenew: true,
    monitorSession: true,
    staleStateAgeInSeconds: 600,
    userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
    onSigninCallback,
};
