import { type PropsWithChildren, useEffect, useLayoutEffect, useRef } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { config } from '../config';
import { EVENT_USER_LANGUAGE_CHANGED, EVENT_USER_PROFILE_CHANGED } from '@rio-cloud/rio-user-menu-component';
import { ErrorResponse } from 'oidc-client-ts';
import { useAppSelector } from '../configuration/setup/hooks';
import { getWasLoggedInBefore } from '../configuration/login/loginSlice';
import { loginStorage } from '../configuration/login/storage';
import { trace } from '../configuration/setup/trace';
import { OAuthConfig } from '../configuration';
import { runInBackground } from '../configuration/setup/backgroundActions';
import { adaptPublishedInfo } from '../configuration/login/login';
import { accessToken } from '../configuration/tokenHandling/accessToken';

const RETRY_SIGNIN_TIMEOUT_IN_MS = 30000;

const isRunningInIframe = () => window.self !== window.top;

const saveCurrentRoute = () => {
    const initialRoute = [window.location.hash, window.location.search].join('').replace(/^#\/?/u, '');
    loginStorage.saveRoute(initialRoute);
    trace('saving initial route', initialRoute);
};

export type AutoLoginProps = PropsWithChildren & {
    oauthConfig: OAuthConfig;
};

// TODO: PORTALOUT-2875: Break this function down into smaller parts
// eslint-disable-next-line max-lines-per-function
export const AutoLogin = ({ oauthConfig, children }: AutoLoginProps) => {
    const { user, isAuthenticated, activeNavigator, isLoading, error, events, signinRedirect, signinSilent } =
        useAuth();

    const wasLoggedInBefore = useAppSelector(getWasLoggedInBefore);

    // automatically sign-in
    useEffect(() => {
        if (!hasAuthParams() && !isAuthenticated && !activeNavigator && !isLoading && !error) {
            // check if there is an active session and if so use it to sign in silently
            trace('AutoLogin: initiating silent login');
            void signinSilent();
        }
    }, [isAuthenticated, activeNavigator, isLoading, error, signinSilent]);

    useEffect(() => {
        if (isAuthenticated && user) {
            trace('AutoLogin: detected change in user session');
            oauthConfig.onSessionRenewed(adaptPublishedInfo(user));
        }
    }, [oauthConfig, isAuthenticated, user]);

    useEffect(() => {
        if (error) {
            trace('AutoLogin: got an error', error);
        }
    }, [error]);

    useEffect(() => {
        if (
            error &&
            error.innerError instanceof ErrorResponse &&
            error.innerError.error &&
            [
                // as per https://openid.net/specs/openid-connect-core-1_0.html#AuthError
                'interaction_required',
                'account_selection_required',
                'consent_required',
                'login_required',
            ].includes(error.innerError.error)
        ) {
            if (!wasLoggedInBefore && !isRunningInIframe()) {
                // perform initial login with redirect
                trace('AutoLogin: initiating initial login with redirect');
                saveCurrentRoute();
                void signinRedirect();
            } else {
                trace('AutoLogin: could not successfully authenticate user, retrying in 30s');
                setTimeout(() => runInBackground(signinSilent()), RETRY_SIGNIN_TIMEOUT_IN_MS);
            }
            // oauth provider needs some form of end-user interaction,
            // due to not being logged in, or similar reasons
            // => not a real error => ignore error so user can proceed to click "login" manually
        }
    }, [error, signinRedirect, signinSilent, wasLoggedInBefore]);

    // some debugging log statements to monitor events
    useEffect(() => {
        return events.addUserSignedIn(() => trace('user signed in'));
    }, [events]);
    useEffect(() => {
        return events.addUserLoaded((loadedUser) => trace('AutoLogin: user loaded', loadedUser.profile.sub));
    }, [events]);
    useEffect(() => {
        return events.addUserSignedIn(() => trace('AutoLogin: user signed in'));
    }, [events]);
    useEffect(() => {
        return events.addUserSessionChanged(() => trace('AutoLogin: session changed'));
    }, [events]);
    useEffect(() => {
        return events.addUserUnloaded(() => trace('AutoLogin: user unloaded'));
    }, [events]);
    useEffect(() => {
        return events.addUserSignedOut(() => {
            // this function gets called when user signs out in another tab
            // and the session iframe indicates 'changed' status
            trace('AutoLogin: user signed out');
            oauthConfig.onSessionExpired();
        });
    }, [oauthConfig, events]);

    useEffect(() => {
        const callback = () => runInBackground(signinSilent());
        document.addEventListener(EVENT_USER_LANGUAGE_CHANGED, callback);
        return () => document.removeEventListener(EVENT_USER_LANGUAGE_CHANGED, callback);
    }, [signinSilent]);

    useEffect(() => {
        const callback = () => runInBackground(signinSilent());
        document.addEventListener(EVENT_USER_PROFILE_CHANGED, callback);
        return () => document.removeEventListener(EVENT_USER_PROFILE_CHANGED, callback);
    }, [signinSilent]);

    if (
        (isLoading && !wasLoggedInBefore) ||
        window.location.href.startsWith(config.login.redirectUri!!) ||
        (isAuthenticated && !accessToken.hasAccessToken())
    ) {
        // For a fresh login the loading screen is shown shortly before the redirect to the login
        // mask and after returning from the OIDC provider before the final application is rendered.
        //
        // For a silent login the loading screen is shown for a short moment between the initial RIO spinner
        // and the final application is rendered.
        return <KeepPageLoader />;
    }

    if (!isAuthenticated && !wasLoggedInBefore) {
        // TODO: PORTALOUT-2875 how should an error look like?
        return <div>Unable to log in</div>;
    }

    return children;
};

const innerHtmlToRestore = document.getElementById('pageLoader')?.innerHTML;
const KeepPageLoader = () => {
    // TODO: PORTALOUT-2890 Check if this is the best thing we can do. Known behavior:
    // * This still leads to changes in the DOM, which leads to a reset of the animation.
    // * The inner HTML is only reliable the one we want before the first rendering. Therefore, we
    //   already extract it during module import. Not sure why it doesn't reliably work during the first render.

    return <div id="pageLoader" dangerouslySetInnerHTML={{ __html: innerHtmlToRestore ?? '' }} />;
};
