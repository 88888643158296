import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../../configuration/setup/hooks';
import { compoundManagementSlice } from '../reducers/compoundManagementSlice';
import { serviceOrdersSlice } from '../reducers/serviceOrdersSlice';
import { transportAssignmentsSlice } from '../reducers/transportAssignmentsSlice';
import { SERVICE_ORDER_ROUTE } from './routes';

export const MAX_LENGTH_SEARCH_TERM = 35;

export const RouteUpdater = () => {
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();

    const vin = searchParams.get('vin')?.trim()?.substring(0, MAX_LENGTH_SEARCH_TERM);

    useEffect(() => {
        // Implement deep linking or react to window location change here

        // close sidebar and reset filters and sorting when navigating
        dispatch(transportAssignmentsSlice.actions.setInitialState());
        dispatch(serviceOrdersSlice.actions.setInitialState());
        dispatch(compoundManagementSlice.actions.setInitialState());
    }, [dispatch, pathname]);

    useEffect(() => {
        if (pathname.startsWith(SERVICE_ORDER_ROUTE) && typeof vin === 'string') {
            dispatch(serviceOrdersSlice.actions.setSearchTerm(vin));
            window.location.replace(`#${pathname}`);
        }
    }, [dispatch, pathname, vin]);

    return null;
};
