import {
    Arrival,
    CompoundLocation,
    DepartedVehicle,
    DepartedVehiclesPage,
    Departure,
    ExpectedArrival,
    ExpectedDeparture,
    FreightForwarder,
    IncomingVehicle,
    IncomingVehiclesPage,
    InventoryVehicle,
    InventoryVehiclesPage,
    MeansOfTransport,
    OutgoingVehicle,
    OutgoingVehiclesPage,
} from '../../../compoundManagement/compoundManagement.types';
import { DepartedVehiclesTableColumn } from '../../../compoundManagement/departed/table/DepartedVehiclesTableColumn';
import { IncomingVehiclesTableColumn } from '../../../compoundManagement/incoming/table/IncomingVehiclesTableColumn';
import { VehicleInventoryTableColumn } from '../../../compoundManagement/inventory/table/VehicleInventoryTableColumn';
import { OutgoingVehiclesTableColumn } from '../../../compoundManagement/outgoing/table/OutgoingVehiclesTableColumn';
import { parseStringDate } from '../../../sharedComponents/dateHelper';
import { SortDirection, TableSort } from '../../../sharedComponents/table/SortedTableHeader';
import type {
    GetDepartedVehiclesQueryParams,
    GetIncomingVehiclesQueryParams,
    GetInventoryVehiclesQueryParams,
    GetOutgoingVehiclesQueryParams,
} from '../../compoundManagementApi';
import {
    ApiArrival,
    ApiCompoundLocation,
    ApiDepartedVehicle,
    ApiDepartedVehicleQueryParameter,
    ApiDepartedVehicleSort,
    ApiDepartedVehiclesPage,
    ApiDeparture,
    ApiExpectedArrival,
    ApiExpectedDeparture,
    ApiFreightForwarder,
    ApiIncomingVehicle,
    ApiIncomingVehicleQueryParameter,
    ApiIncomingVehicleSort,
    ApiIncomingVehiclesPage,
    ApiInventoryVehicle,
    ApiInventoryVehicleQueryParameter,
    ApiInventoryVehicleSort,
    ApiInventoryVehiclesPage,
    ApiOutgoingVehicle,
    ApiOutgoingVehicleQueryParameter,
    ApiOutgoingVehicleSort,
    ApiOutgoingVehiclesPage,
} from '../../types/compoundManagement/compoundManagementApi.types';
import { ApiMeansOfTransport } from '../../types/transportAssignment/transportApi.types';
import { mapToAddress } from '../addressMappers';
import { mapToMeansOfTransportType } from '../transportAssignment/transportAssignmentsMapper';
import { mapToApiVehicleType } from '../vehicleMappers';
import { mapToVehicle } from './vehicleCompoundMapper';

export const mapToIncomingVehicleQueryParameters = (
    queryParameters: GetIncomingVehiclesQueryParams,
): ApiIncomingVehicleQueryParameter => ({
    location_identifier: queryParameters.locationId,
    vehicle_type: queryParameters.vehicleType && mapToApiVehicleType(queryParameters.vehicleType),
    manufacturer: queryParameters.manufacturer,
    expected_arrival_date_start_at: queryParameters.expectedArrivalDateStartAt?.toISOString(),
    expected_arrival_date_end_at: queryParameters.expectedArrivalDateEndAt?.toISOString(),
    vin: queryParameters.vin,
    limit: queryParameters.limit?.toFixed(0),
    sort: queryParameters.sortBy && mapToApiIncomingVehicleSort(queryParameters.sortBy),
});

export const mapToInventoryVehicleQueryParameters = (
    queryParameters: GetInventoryVehiclesQueryParams,
): ApiInventoryVehicleQueryParameter => ({
    location_identifier: queryParameters.locationId,
    vehicle_type: queryParameters.vehicleType && mapToApiVehicleType(queryParameters.vehicleType),
    manufacturer: queryParameters.manufacturer,
    arrival_date_start_at: queryParameters.arrivalDateStartAt?.toISOString(),
    arrival_date_end_at: queryParameters.arrivalDateEndAt?.toISOString(),
    vin: queryParameters.vin,
    limit: queryParameters.limit?.toFixed(0),
    sort: queryParameters.sortBy && mapToApiInventoryVehicleSort(queryParameters.sortBy),
});

export const mapToOutgoingVehicleQueryParameters = (
    queryParameters: GetOutgoingVehiclesQueryParams,
): ApiOutgoingVehicleQueryParameter => ({
    location_identifier: queryParameters.locationId,
    vehicle_type: queryParameters.vehicleType && mapToApiVehicleType(queryParameters.vehicleType),
    manufacturer: queryParameters.manufacturer,
    expected_departure_date_start_at: queryParameters.expectedDepartureDateStartAt?.toISOString(),
    expected_departure_date_end_at: queryParameters.expectedDepartureDateEndAt?.toISOString(),
    vin: queryParameters.vin,
    limit: queryParameters.limit?.toFixed(0),
    sort: queryParameters.sortBy && mapToApiOutgoingVehicleSort(queryParameters.sortBy),
});

export const mapToDepartedVehicleQueryParameters = (
    queryParameters: GetDepartedVehiclesQueryParams,
): ApiDepartedVehicleQueryParameter => ({
    location_identifier: queryParameters.locationId,
    vehicle_type: queryParameters.vehicleType && mapToApiVehicleType(queryParameters.vehicleType),
    manufacturer: queryParameters.manufacturer,
    arrival_date_start_at: queryParameters.arrivalDateStartAt?.toISOString(),
    arrival_date_end_at: queryParameters.arrivalDateEndAt?.toISOString(),
    departure_date_start_at: queryParameters.departureDateStartAt?.toISOString(),
    departure_date_end_at: queryParameters.departureDateEndAt?.toISOString(),
    vin: queryParameters.vin,
    limit: queryParameters.limit?.toFixed(0),
    sort: queryParameters.sortBy && mapToApiDepartedVehicleSort(queryParameters.sortBy),
});

export const mapToApiIncomingVehicleSort = (
    sorting: TableSort<IncomingVehiclesTableColumn>,
): ApiIncomingVehicleSort => {
    if (sorting.dir === SortDirection.ASCENDING) {
        switch (sorting.column) {
            case IncomingVehiclesTableColumn.EXPECTED_ARRIVAL_DATE:
                return ApiIncomingVehicleSort.EXPECTED_ARRIVAL_AT_ASC;
            case IncomingVehiclesTableColumn.LOCATION_ID:
                return ApiIncomingVehicleSort.LOCATION_IDENTIFIER_ASC;
            case IncomingVehiclesTableColumn.ARRIVAL_FREIGHT_FORWARDER:
                return ApiIncomingVehicleSort.FREIGHT_FORWARDER_ASC;
        }
    } else if (sorting.dir === SortDirection.DESCENDING) {
        switch (sorting.column) {
            case IncomingVehiclesTableColumn.EXPECTED_ARRIVAL_DATE:
                return ApiIncomingVehicleSort.EXPECTED_ARRIVAL_AT_DESC;
            case IncomingVehiclesTableColumn.LOCATION_ID:
                return ApiIncomingVehicleSort.LOCATION_IDENTIFIER_DESC;
            case IncomingVehiclesTableColumn.ARRIVAL_FREIGHT_FORWARDER:
                return ApiIncomingVehicleSort.FREIGHT_FORWARDER_DESC;
        }
    }
    throw new Error('Specified sort order is not implemented');
};

export const mapToApiInventoryVehicleSort = (
    sorting: TableSort<VehicleInventoryTableColumn>,
): ApiInventoryVehicleSort => {
    if (sorting.dir === SortDirection.ASCENDING) {
        switch (sorting.column) {
            case VehicleInventoryTableColumn.ARRIVAL_DATE:
                return ApiInventoryVehicleSort.ARRIVAL_COMPLETED_AT_ASC;
            case VehicleInventoryTableColumn.LOCATION_ID:
                return ApiInventoryVehicleSort.LOCATION_IDENTIFIER_ASC;
            case VehicleInventoryTableColumn.ARRIVAL_FREIGHT_FORWARDER:
                return ApiInventoryVehicleSort.FREIGHT_FORWARDER_ASC;
        }
    } else if (sorting.dir === SortDirection.DESCENDING) {
        switch (sorting.column) {
            case VehicleInventoryTableColumn.ARRIVAL_DATE:
                return ApiInventoryVehicleSort.ARRIVAL_COMPLETED_AT_DESC;
            case VehicleInventoryTableColumn.LOCATION_ID:
                return ApiInventoryVehicleSort.LOCATION_IDENTIFIER_DESC;
            case VehicleInventoryTableColumn.ARRIVAL_FREIGHT_FORWARDER:
                return ApiInventoryVehicleSort.FREIGHT_FORWARDER_DESC;
        }
    }
    throw new Error('Specified sort order is not implemented');
};

export const mapToApiOutgoingVehicleSort = (
    sorting: TableSort<OutgoingVehiclesTableColumn>,
): ApiOutgoingVehicleSort => {
    if (sorting.dir === SortDirection.ASCENDING) {
        switch (sorting.column) {
            case OutgoingVehiclesTableColumn.EXPECTED_DEPARTURE_DATE:
                return ApiOutgoingVehicleSort.EXPECTED_DEPARTURE_AT_ASC;
            case OutgoingVehiclesTableColumn.LOCATION_ID:
                return ApiOutgoingVehicleSort.LOCATION_IDENTIFIER_ASC;
            case OutgoingVehiclesTableColumn.DEPARTURE_FREIGHT_FORWARDER:
                return ApiOutgoingVehicleSort.FREIGHT_FORWARDER_ASC;
        }
    } else if (sorting.dir === SortDirection.DESCENDING) {
        switch (sorting.column) {
            case OutgoingVehiclesTableColumn.EXPECTED_DEPARTURE_DATE:
                return ApiOutgoingVehicleSort.EXPECTED_DEPARTURE_AT_DESC;
            case OutgoingVehiclesTableColumn.LOCATION_ID:
                return ApiOutgoingVehicleSort.LOCATION_IDENTIFIER_DESC;
            case OutgoingVehiclesTableColumn.DEPARTURE_FREIGHT_FORWARDER:
                return ApiOutgoingVehicleSort.FREIGHT_FORWARDER_DESC;
        }
    }
    throw new Error('Specified sort order is not implemented');
};

export const mapToApiDepartedVehicleSort = (
    sorting: TableSort<DepartedVehiclesTableColumn>,
): ApiDepartedVehicleSort => {
    if (sorting.dir === SortDirection.ASCENDING) {
        switch (sorting.column) {
            case DepartedVehiclesTableColumn.ARRIVAL_DATE:
                return ApiDepartedVehicleSort.ARRIVAL_COMPLETED_AT_ASC;
            case DepartedVehiclesTableColumn.DEPARTURE_DATE:
                return ApiDepartedVehicleSort.DEPARTURE_COMPLETED_AT_ASC;
            case DepartedVehiclesTableColumn.LOCATION_ID:
                return ApiDepartedVehicleSort.LOCATION_IDENTIFIER_ASC;
            case DepartedVehiclesTableColumn.ARRIVAL_FREIGHT_FORWARDER:
                return ApiDepartedVehicleSort.ARRIVAL_FREIGHT_FORWARDER_ASC;
            case DepartedVehiclesTableColumn.DEPARTURE_FREIGHT_FORWARDER:
                return ApiDepartedVehicleSort.DEPARTURE_FREIGHT_FORWARDER_ASC;
        }
    } else if (sorting.dir === SortDirection.DESCENDING) {
        switch (sorting.column) {
            case DepartedVehiclesTableColumn.ARRIVAL_DATE:
                return ApiDepartedVehicleSort.ARRIVAL_COMPLETED_AT_DESC;
            case DepartedVehiclesTableColumn.DEPARTURE_DATE:
                return ApiDepartedVehicleSort.DEPARTURE_COMPLETED_AT_DESC;
            case DepartedVehiclesTableColumn.LOCATION_ID:
                return ApiDepartedVehicleSort.LOCATION_IDENTIFIER_DESC;
            case DepartedVehiclesTableColumn.ARRIVAL_FREIGHT_FORWARDER:
                return ApiDepartedVehicleSort.ARRIVAL_FREIGHT_FORWARDER_DESC;
            case DepartedVehiclesTableColumn.DEPARTURE_FREIGHT_FORWARDER:
                return ApiDepartedVehicleSort.DEPARTURE_FREIGHT_FORWARDER_DESC;
        }
    }
    throw new Error('Specified sort order is not implemented');
};

export const mapToIncomingVehicle = (apiVehicle: ApiIncomingVehicle): IncomingVehicle => ({
    id: apiVehicle.id,
    compoundLocation: mapToCompoundLocation(apiVehicle.compound_location),
    arrival: mapToExpectedArrival(apiVehicle.arrival),
    vehicle: mapToVehicle(apiVehicle.vehicle),
});

export const mapToInventoryVehicle = (apiVehicle: ApiInventoryVehicle): InventoryVehicle => ({
    id: apiVehicle.id,
    compoundLocation: mapToCompoundLocation(apiVehicle.compound_location),
    arrival: mapToArrival(apiVehicle.arrival),
    vehicle: mapToVehicle(apiVehicle.vehicle),
});

export const mapToOutgoingVehicle = (apiVehicle: ApiOutgoingVehicle): OutgoingVehicle => ({
    id: apiVehicle.id,
    compoundLocation: mapToCompoundLocation(apiVehicle.compound_location),
    departure: mapToExpectedDeparture(apiVehicle.departure),
    vehicle: mapToVehicle(apiVehicle.vehicle),
});

export const mapToDepartedVehicle = (apiVehicle: ApiDepartedVehicle): DepartedVehicle => ({
    id: apiVehicle.id,
    compoundLocation: mapToCompoundLocation(apiVehicle.compound_location),
    arrival: mapToArrival(apiVehicle.arrival),
    departure: mapToDeparture(apiVehicle.departure),
    vehicle: mapToVehicle(apiVehicle.vehicle),
});

export const mapToIncomingVehiclesPage = (apiPage: ApiIncomingVehiclesPage): IncomingVehiclesPage => ({
    items: apiPage.items.map(mapToIncomingVehicle),
    hasMore: apiPage.has_more,
});

export const mapToInventoryVehiclesPage = (apiPage: ApiInventoryVehiclesPage): InventoryVehiclesPage => ({
    items: apiPage.items.map(mapToInventoryVehicle),
    hasMore: apiPage.has_more,
});

export const mapToOutgoingVehiclesPage = (apiPage: ApiOutgoingVehiclesPage): OutgoingVehiclesPage => ({
    items: apiPage.items.map(mapToOutgoingVehicle),
    hasMore: apiPage.has_more,
});

export const mapToDepartedVehiclesPage = (apiPage: ApiDepartedVehiclesPage): DepartedVehiclesPage => ({
    items: apiPage.items.map(mapToDepartedVehicle),
    hasMore: apiPage.has_more,
});

const mapToCompoundLocation = (apiCompoundLocation: ApiCompoundLocation): CompoundLocation => ({
    identifier: apiCompoundLocation.identifier,
    address: mapToAddress(apiCompoundLocation.address),
    timeZone: apiCompoundLocation.time_zone,
});

export const mapToFreightForwarder = (apiFreightForwarder: ApiFreightForwarder): FreightForwarder => ({
    name: apiFreightForwarder.name,
    additionalName: apiFreightForwarder.additional_name,
});

export const mapToMeansOfTransport = (apiMeansOfTransport: ApiMeansOfTransport): MeansOfTransport => {
    return {
        id: apiMeansOfTransport.id,
        type: mapToMeansOfTransportType(apiMeansOfTransport.type),
    };
};

export const mapToExpectedArrival = (apiExpectedArrival: ApiExpectedArrival): ExpectedArrival => ({
    expectedArrivalAt: parseStringDate(apiExpectedArrival.expected_arrival_at),
    freightForwarder:
        apiExpectedArrival.freight_forwarder && mapToFreightForwarder(apiExpectedArrival.freight_forwarder),
    meansOfTransport: mapToMeansOfTransport(apiExpectedArrival.means_of_transport),
    isCancelled: apiExpectedArrival.is_cancelled,
});

export const mapToArrival = (apiArrival: ApiArrival): Arrival => ({
    arrivalCompletedAt: parseStringDate(apiArrival.arrival_completed_at),
    freightForwarder: apiArrival.freight_forwarder && mapToFreightForwarder(apiArrival.freight_forwarder),
    isCancelled: apiArrival.is_cancelled,
    isFinalized: apiArrival.is_finalized,
});

export const mapToExpectedDeparture = (apiExpectedDeparture: ApiExpectedDeparture): ExpectedDeparture => ({
    expectedDepartureAt: parseStringDate(apiExpectedDeparture.expected_departure_at),
    freightForwarder:
        apiExpectedDeparture.freight_forwarder && mapToFreightForwarder(apiExpectedDeparture.freight_forwarder),
    meansOfTransport: mapToMeansOfTransport(apiExpectedDeparture.means_of_transport),
    isCancelled: apiExpectedDeparture.is_cancelled,
});

export const mapToDeparture = (apiDeparture: ApiDeparture): Departure => ({
    departureCompletedAt: parseStringDate(apiDeparture.departure_completed_at),
    freightForwarder: apiDeparture.freight_forwarder && mapToFreightForwarder(apiDeparture.freight_forwarder),
    isFinalized: apiDeparture.is_finalized,
});
