import TableToolbar from '@rio-cloud/rio-uikit/TableToolbar';
import { ServiceOrderStatusFilter } from './ServiceOrderStatusFilter';
import TableViewToggles, { TableViewTogglesViewType } from '@rio-cloud/rio-uikit/TableViewToggles';
import { ServiceOrderToolbarSearch } from './ServiceOrderToolbarSearch';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/hooks';
import { getTableViewType, viewSlice } from '../../../reducers/viewSlice';
import { ServiceOrderCategoriesSelection } from './ServiceOrderCategoriesSelection';
import { ServiceOrderFilterModal } from './ServiceOrderFilterModal';
import { FeatureToggles, useFeatureToggle } from '../../../../../configuration/featureToggle/toggleHook';
import { ServiceOrderExportModal } from './ServiceOrderExportModal';

export const SO_EXCEL_DOWNLOAD_BUTTON = 'excel-download-button';

export const ServiceOrderTableToolbar = () => {
    const viewType = useAppSelector(getTableViewType);
    const dispatch = useAppDispatch();
    const isExcelExportEnabled = useFeatureToggle(FeatureToggles.SERVICE_ORDER_EXCEL, false).value;

    const handleViewTypeChange = (newViewType: TableViewTogglesViewType) =>
        dispatch(viewSlice.actions.setTableViewType(newViewType));

    return (
        <TableToolbar>
            <div className="table-toolbar-container">
                <div className="table-toolbar-group-left">
                    <div className="table-toolbar-column">
                        <div className="btn-toolbar table-btn-toolbar">
                            <ServiceOrderCategoriesSelection />
                        </div>
                    </div>
                </div>
                <div className="table-toolbar-group-right">
                    <div className="table-toolbar-column table-toolbar-column-spacer min-width-200">
                        <ServiceOrderStatusFilter />
                    </div>
                    <div className="table-toolbar-column">
                        <ServiceOrderFilterModal />
                    </div>
                    <div className="table-toolbar-column">
                        <ServiceOrderToolbarSearch />
                    </div>
                    {isExcelExportEnabled ? (
                        <div className="table-toolbar-column">
                            <ServiceOrderExportModal />
                        </div>
                    ) : undefined}
                    <div className="table-toolbar-column">
                        <TableViewToggles initialViewType={viewType} onViewTypeChange={handleViewTypeChange} />
                    </div>
                </div>
            </div>
        </TableToolbar>
    );
};
