import { UserManager } from 'oidc-client-ts';
import { config } from '../../config';
import { clientSideFeatureToggles } from '../featureToggle/clientSideFeatureToggles';
import { loginStorage } from '../login/storage';
import { addBreadcrumbToSentry, reportErrorToSentry } from './sentry';
import { trace } from './trace';

const saveLoginRedirectData = () => {
    const initialRoute = [window.location.hash, window.location.search].join('').replace(/^#\/?/u, '');
    loginStorage.saveRoute(initialRoute);
    loginStorage.saveLoginRedirectTimestamp(new Date().toISOString());
    trace('saving initial route', initialRoute);
};

const doSignIn = (userManager: UserManager, isFreshRedirect: boolean) => {
    if (clientSideFeatureToggles.useSigninCallback && isFreshRedirect) {
        return userManager.signinCallback();
    } else {
        return userManager.signinSilent();
    }
};

export const attemptInitialSignIn = async (userManager: UserManager) => {
    const isFreshRedirect = clientSideFeatureToggles.useSigninCallback
        ? window.location.href.startsWith(config.login.redirectUri as string)
        : window.location.href.includes('redirected');

    const initialRoute = loginStorage.getRoute();
    const loginRedirectTimestamp = loginStorage.getLoginRedirectTimestamp();

    addBreadcrumbToSentry({
        category: 'oauth',
        level: 'info',
        message: 'Initial sign in',
        data: {
            isFreshRedirect,
            initialRoute,
            loginRedirectTimestamp,
        },
    });

    try {
        const user = await doSignIn(userManager, isFreshRedirect);

        trace('initialRoute lookup', initialRoute);

        if (clientSideFeatureToggles.useSigninCallback) {
            window.history.replaceState({}, document.title, '/');
        }
        if (initialRoute !== null && initialRoute !== '' && isFreshRedirect) {
            trace(`Go to location "/${initialRoute}"`);
            window.location.replace(`#/${initialRoute}`);
        }

        loginStorage.clear();
        return user;
    } catch (error) {
        trace('oidc.signinSilent failed, trying page redirect...', error);

        if (config.login.preventRedirect) {
            // eslint-disable-next-line no-console
            console.warn('[feature/login] redirect prevented due to config. Error was', error);
        } else if (isFreshRedirect && loginRedirectTimestamp != null) {
            trace('oidc.signinSilent.error', 'redirect prevented due to suspicious signin error', error);
            loginStorage.clear();
            reportErrorToSentry(error);
        } else {
            saveLoginRedirectData();
            await userManager.signinRedirect();
            return null;
        }

        trace('auth problem?', error);
        throw new Error(`Need to sign in${isFreshRedirect ? ' after redirect' : ''}`);
    }
};
