import Multiselect from '@rio-cloud/rio-uikit/Multiselect';
import { getServiceNameByType } from '../../serviceOrderUtils';
import { ServiceType } from '../../serviceType.types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useId } from 'react';

type ServiceTypeFilterProps = {
    value: ServiceType[];
    onChange: (filter: ServiceType[]) => void;
};
export const ServiceTypeFilter = ({ value: filter, onChange: onFilterChange }: ServiceTypeFilterProps) => {
    const intl = useIntl();
    const id = useId();

    const options = Object.values(ServiceType).map((serviceType) => ({
        id: serviceType,
        label: getServiceNameByType(serviceType),
    }));

    return (
        <>
            <label htmlFor={id}>
                <FormattedMessage id="outboundOrderBook.serviceOrders.export.filter.serviceName" />
            </label>
            <Multiselect
                id={id}
                placeholder={intl.formatMessage({
                    id: 'outboundPortal.serviceOrders.table.toolbar.filter.serviceCode.placeholder',
                })}
                options={options}
                value={filter}
                onChange={(selectedTypes: string[]) => onFilterChange(selectedTypes as ServiceType[])}
                multiline
            />
        </>
    );
};
