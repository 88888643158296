import './polyfills';
import './configuration/lang/momentLocales';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';

import { config } from './config';
import { main, OAuthConfig } from './configuration';
import { handleLoginRedirect } from './configuration/login/redirect';
import { ErrorBoundary } from './features/app/ErrorBoundary';
import { App } from './features/app/App';
import { createRoot } from 'react-dom/client';
import { runInBackground } from './configuration/setup/backgroundActions';
import { EnhancedStore } from '@reduxjs/toolkit';
import {
    initializeFeatureToggles,
    clientSideFeatureToggles,
} from './configuration/featureToggle/clientSideFeatureToggles';
import { RioAuthProvider } from './auth/RioAuthProvider';
import { trace } from './configuration/setup/trace';

// Make sure the features toggle detection code runs as early as possible,
// because other code might change the URL later on.
initializeFeatureToggles();

const renderApplication = (store: EnhancedStore) => {
    const container = document.getElementById('root');
    const root = createRoot(container!);

    root.render(
        <ErrorBoundary>
            <Provider store={store}>
                <HashRouter>
                    <App />
                </HashRouter>
            </Provider>
        </ErrorBoundary>,
    );
};

const renderAppWithOidcContext = (store: EnhancedStore, oauthConfig: OAuthConfig) => {
    const container = document.getElementById('root');
    const root = createRoot(container!);

    root.render(
        <ErrorBoundary>
            <Provider store={store}>
                <HashRouter>
                    <RioAuthProvider oauthConfig={oauthConfig}>
                        <App />
                    </RioAuthProvider>
                </HashRouter>
            </Provider>
        </ErrorBoundary>,
    );
};

const isRedirect = window.location.href.startsWith(config.login.redirectUri as string);
const runsInIframe = 'parent' in window && window.parent !== window;

if (clientSideFeatureToggles.useOidcContext) {
    trace('index: Using react-oidc-context');
    runInBackground(main(renderAppWithOidcContext));
} else if (clientSideFeatureToggles.useSigninCallback) {
    trace('index: Using signin callback');
    // If we drop support for older browsers we could use a top level await here instead
    // of the runInBackground function. But right now our target browsers do not all
    // support top level awaits.
    runInBackground(isRedirect && runsInIframe ? handleLoginRedirect() : main(renderApplication));
} else {
    // If we drop support for older browsers we could use a top level await here instead
    // of the runInBackground function. But right now our target browsers do not all
    // support top level awaits.
    runInBackground(isRedirect ? handleLoginRedirect() : main(renderApplication));
}
