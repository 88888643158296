import Dialog from '@rio-cloud/rio-uikit/Dialog';
import { FormattedMessage } from 'react-intl';
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/hooks';
import { getModalFilters, transportAssignmentsSlice } from '../../../reducers/transportAssignmentsSlice';
import { isBefore, isDate } from 'date-fns';
import { DateInput, isValidDate, validateDateTimeFields } from '../../../sharedComponents/dateHelper';
import {
    FilterModalButton,
    FilterModalDatePicker,
    FilterModalFooter,
    FilterModalTextInput,
} from '../../../sharedComponents/filter/FilterModal';

interface LocalModalFilters {
    startDateFilter: DateInput;
    endDateFilter: DateInput;
    pickUpCityFilter: string | undefined;
    deliveryCityFilter: string | undefined;
}

const UNSET_FILTERS: LocalModalFilters = {
    startDateFilter: undefined,
    endDateFilter: undefined,
    pickUpCityFilter: undefined,
    deliveryCityFilter: undefined,
};

const TransportAssignmentFilterModalContent = ({
    localFilters,
    setLocalFilters,
    hasStartDateTimeError,
    hasEndDateTimeError,
    hasTimeRangeOrderError,
}: {
    localFilters: LocalModalFilters;
    setLocalFilters: Dispatch<SetStateAction<LocalModalFilters>>;
    hasStartDateTimeError: boolean;
    hasEndDateTimeError: boolean;
    hasTimeRangeOrderError: boolean;
}) => {
    const nextDateValue = (date: Moment | string | undefined) =>
        moment.isMoment(date) ? date.toDate() : date === '' ? undefined : date;
    const nextCityValue = (value: string | undefined) => (value === '' ? undefined : value);

    return (
        <div className="margin-bottom-20">
            <label className="margin-bottom-10">
                <FormattedMessage id="outboundPortal.transportAssignments.table.toolbar.filter.pickUpTimeframe" />
            </label>
            <div className="display-flex justify-content-start row">
                <div className="col-6 padding-right-5">
                    <FilterModalDatePicker
                        labelId="outboundPortal.common.table.from"
                        hasFormatError={hasStartDateTimeError}
                        hasTimeRangeOrderError={false}
                        value={localFilters.startDateFilter}
                        onChange={(date: Moment | string | undefined) =>
                            setLocalFilters((prevState) => ({ ...prevState, startDateFilter: nextDateValue(date) }))
                        }
                    />
                </div>
                <div className="col-6 padding-left-5">
                    <FilterModalDatePicker
                        labelId="outboundPortal.common.table.to"
                        hasFormatError={hasEndDateTimeError}
                        hasTimeRangeOrderError={hasTimeRangeOrderError}
                        value={localFilters.endDateFilter}
                        onChange={(date: Moment | string | undefined) =>
                            setLocalFilters((prevState) => ({ ...prevState, endDateFilter: nextDateValue(date) }))
                        }
                    />
                </div>
            </div>
            <hr className="margin-top-20 margin-bottom-20" />
            <FilterModalTextInput
                headerMessageId="outboundPortal.transportAssignments.table.toolbar.filter.pickUpCity"
                placeholderMessageId="outboundOrderBook.common.table.toolbar.filter.enterLocation"
                value={localFilters.pickUpCityFilter}
                onChange={(event) => {
                    const value = event.currentTarget.value;
                    setLocalFilters((prevState) => ({ ...prevState, pickUpCityFilter: nextCityValue(value) }));
                }}
            />
            <div className="margin-top-20">
                <FilterModalTextInput
                    headerMessageId="outboundPortal.transportAssignments.table.toolbar.filter.deliveryCity"
                    placeholderMessageId="outboundOrderBook.common.table.toolbar.filter.enterLocation"
                    value={localFilters.deliveryCityFilter}
                    onChange={(event) => {
                        const value = event.currentTarget.value;
                        setLocalFilters((prevState) => ({ ...prevState, deliveryCityFilter: nextCityValue(value) }));
                    }}
                />
            </div>
        </div>
    );
};

const trimCityFilterInputs = (localFilters: LocalModalFilters) => ({
    pickUpCityFilter: localFilters.pickUpCityFilter?.trim(),
    deliveryCityFilter: localFilters.deliveryCityFilter?.trim(),
});

const discardInvalidDateInputs = (localFilters: LocalModalFilters) => ({
    startDateFilter: isDate(localFilters.startDateFilter) ? (localFilters.startDateFilter as Date) : undefined,
    endDateFilter: isDate(localFilters.endDateFilter) ? (localFilters.endDateFilter as Date) : undefined,
});

export const TransportAssignmentFilterModal = () => {
    const dispatch = useAppDispatch();
    const modalFilters = useAppSelector(getModalFilters);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [localFilters, setLocalFilters] = useState<LocalModalFilters>(UNSET_FILTERS);

    const isFilterButtonActive =
        modalFilters.startDateFilter !== undefined ||
        modalFilters.endDateFilter !== undefined ||
        modalFilters.pickUpCityFilter !== undefined ||
        modalFilters.deliveryCityFilter !== undefined;

    const handleOnClickApply = () => {
        setLocalFilters({
            ...localFilters,
            ...trimCityFilterInputs(localFilters),
        });
        dispatch(
            transportAssignmentsSlice.actions.setModalFilters({
                ...discardInvalidDateInputs(localFilters),
                ...trimCityFilterInputs(localFilters),
            }),
        );
        setShowFilterModal(false);
    };

    const handleOnClickCancel = () => {
        setLocalFilters(modalFilters);
        setShowFilterModal(false);
    };

    const resetFilters = () => {
        setLocalFilters(UNSET_FILTERS);
    };

    const { hasStartDateTimeError, hasEndDateTimeError, hasTimeRangeOrderError } = useMemo(
        () => validateDateTimeFields(localFilters.startDateFilter, localFilters.endDateFilter),
        [localFilters],
    );

    return (
        <>
            <FilterModalButton isFilterActive={isFilterButtonActive} setShowFilterModal={setShowFilterModal} />
            <Dialog
                show={showFilterModal}
                bsSize={Dialog.SIZE_SM}
                title={<FormattedMessage id="outboundOrderBook.common.table.toolbar.filter.title" />}
                body={
                    <TransportAssignmentFilterModalContent
                        localFilters={localFilters}
                        setLocalFilters={setLocalFilters}
                        hasStartDateTimeError={hasStartDateTimeError}
                        hasEndDateTimeError={hasEndDateTimeError}
                        hasTimeRangeOrderError={hasTimeRangeOrderError}
                    />
                }
                footer={
                    <FilterModalFooter
                        handleOnClickApply={handleOnClickApply}
                        handleOnClickCancel={handleOnClickCancel}
                        resetFilters={resetFilters}
                        isApplyButtonDisabled={hasTimeRangeOrderError || hasStartDateTimeError || hasEndDateTimeError}
                    />
                }
                onClose={handleOnClickCancel}
            />
        </>
    );
};
