import classNames from 'classnames';
import { toZonedTime } from 'date-fns-tz';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { runInBackgroundCallback } from '../../../../configuration/setup/backgroundActions';
import { useAppDispatch } from '../../../../configuration/setup/hooks';
import { transportAssignmentsSlice } from '../../reducers/transportAssignmentsSlice';
import { isValidDate } from '../../sharedComponents/dateHelper';
import {
    defaultExpanderButtonStyle,
    TransportAssignmentSidebarExpanderButtonStyle,
    unconfirmedExpanderButtonStyle,
} from '../buttonStyleAndSettings';
import {
    AddressAndContact,
    MeansOfTransport,
    TransportAssignment,
    TransportAssignmentStatus,
    TransportAssignmentType,
} from '../transportAssignment.types';
import { getTransportAssignmentIds, isBundle, isTransportOrder } from '../TransportAssignmentUtils';
import { DatePickerFormGroup } from './confirmationForm/DatePickerFormGroup';
import { MeansOfTransportFormInput } from './confirmationForm/meansOfTransportInput/MeansOfTransportFormInput';
import { TrailerFormInput } from './confirmationForm/trailerInput/TrailerFormInput';
import { UnloadingAddressFormGroup } from './confirmationForm/UnloadingAddressFormGroup';
import { useTransportAssignmentConfirmationForm } from './confirmationForm/useTransportAssignmentConfirmationForm';
import { IncidentModal } from './details/IncidentModal';

export type ConfirmationFormParams = {
    newConfirmedPickUpDate: Date | undefined;
    newConfirmedDeliveryDate: Date | undefined;
    newMeansOfTransport: MeansOfTransport | undefined;
    newUnloadingAddress: AddressAndContact | undefined;
};

export enum DatePickerType {
    PICKUP_DATE_PICKER,
    DELIVERY_DATE_PICKER,
}

const getActionButtonLabelId = (status: TransportAssignmentStatus, isCapacityOrder: boolean): string => {
    if (status === TransportAssignmentStatus.UNCONFIRMED) {
        return isCapacityOrder
            ? 'outboundPortal.transportAssignments.button.confirmCapacity'
            : 'outboundPortal.transportAssignments.button.confirmOrder';
    }
    return isCapacityOrder
        ? 'outboundPortal.transportAssignments.button.updateCapacity'
        : 'outboundPortal.transportAssignments.button.update';
};

type TransportAssignmentConfirmationFormProps = {
    transportAssignment: TransportAssignment;
    isConfirmPrimaryButton: boolean;
    isProcessingConfirmation: boolean;
    showIncidentButton: boolean;
    handleConfirm: (formParams: ConfirmationFormParams) => Promise<boolean>;
};

// eslint-disable-next-line max-lines-per-function
export const TransportAssignmentConfirmationForm = ({
    transportAssignment,
    handleConfirm,
    isProcessingConfirmation,
    isConfirmPrimaryButton,
    showIncidentButton,
}: TransportAssignmentConfirmationFormProps) => {
    const isCapacityOrder = transportAssignment.type === TransportAssignmentType.TRANSPORT_CAPACITY_ORDER;

    // TODO: Migrate to a form to make the content change and button disabling easier,
    //  since those are provided by the form (disable button when confirming or with invalid content,
    //  or without changes)

    const dispatch = useAppDispatch();

    const {
        assignmentData: { requestedPickUpDate, pickUpTimeZone, requestedDeliveryDate, deliveryTimeZone },
        formState: { confirmedPickUpDate, confirmedDeliveryDate, meansOfTransport, unloadingAddress },
        isFormDisabled,
        validation,
        setConfirmedPickUpDate,
        setConfirmedDeliveryDate,
        setMeansOfTransport,
        setTrailerId,
        setUnloadingAddress,
    } = useTransportAssignmentConfirmationForm(transportAssignment);

    const hasSelectableUnloadingAddress =
        isTransportOrder(transportAssignment) && transportAssignment.hasSelectableUnloadingAddress;

    const { actionButtonStyles, actionButtonIconStyles } = useMemo<TransportAssignmentSidebarExpanderButtonStyle>(
        () => (isConfirmPrimaryButton ? unconfirmedExpanderButtonStyle : defaultExpanderButtonStyle),
        [isConfirmPrimaryButton],
    );

    const closeSidebar = () => {
        dispatch(transportAssignmentsSlice.actions.deselectTransportAssignment());
    };

    const onConfirmTransportOrder = runInBackgroundCallback(async () => {
        const success = await handleConfirm({
            newConfirmedPickUpDate: isValidDate(confirmedPickUpDate) ? confirmedPickUpDate : undefined,
            newConfirmedDeliveryDate: isValidDate(confirmedDeliveryDate) ? confirmedDeliveryDate : undefined,
            newMeansOfTransport: meansOfTransport,
            newUnloadingAddress: unloadingAddress,
        });
        if (success) {
            closeSidebar();
        }
    });

    const unloadingAddressInput = (
        <UnloadingAddressFormGroup
            transportOrderId={getTransportAssignmentIds(transportAssignment)[0]}
            unloadingAddress={unloadingAddress}
            setUnloadingAddress={setUnloadingAddress}
            isBundle={isBundle(transportAssignment)}
            isSelectable={hasSelectableUnloadingAddress}
            validation={validation.unloadingAddress}
            isFormDisabled={isFormDisabled}
        />
    );

    const meansOfTransportInput = (
        <MeansOfTransportFormInput
            meansOfTransport={meansOfTransport}
            setMeansOfTransportId={setMeansOfTransport}
            validation={validation.meansOfTransport}
            isFormDisabled={isFormDisabled}
        />
    );

    return (
        <>
            <div className="display-flex align-items-start">
                {meansOfTransportInput}
                <TrailerFormInput
                    meansOfTransport={meansOfTransport}
                    setTrailerId={setTrailerId}
                    validation={validation.trailer}
                    isFormDisabled={isFormDisabled}
                />
            </div>
            <div className="display-flex align-items-start">{unloadingAddressInput}</div>

            <DatePickerFormGroup
                datePickerType={DatePickerType.PICKUP_DATE_PICKER}
                displayFieldMessageId="outboundPortal.transportAssignments.sidebar.assignmentInformation.requestedPickUpDateInterval"
                inputFieldMessageId="outboundPortal.transportAssignments.sidebar.assignmentInformation.confirmedPickUpDate"
                displayFieldValue={requestedPickUpDate}
                timeZone={pickUpTimeZone}
                inputFieldValue={
                    isValidDate(confirmedPickUpDate)
                        ? toZonedTime(confirmedPickUpDate, pickUpTimeZone)
                        : confirmedPickUpDate
                }
                onChange={setConfirmedPickUpDate}
                validation={validation.confirmedPickUpDate}
                isFormDisabled={isFormDisabled}
            />
            <DatePickerFormGroup
                datePickerType={DatePickerType.DELIVERY_DATE_PICKER}
                displayFieldMessageId="outboundPortal.transportAssignments.sidebar.assignmentInformation.requestedDeliveryDate"
                inputFieldMessageId="outboundPortal.transportAssignments.sidebar.assignmentInformation.confirmedDeliveryDate"
                displayFieldValue={requestedDeliveryDate}
                timeZone={deliveryTimeZone}
                inputFieldValue={
                    isValidDate(confirmedDeliveryDate)
                        ? toZonedTime(confirmedDeliveryDate, deliveryTimeZone)
                        : confirmedDeliveryDate
                }
                onChange={setConfirmedDeliveryDate}
                validation={validation.confirmedDeliveryDate}
                isFormDisabled={isFormDisabled}
            />
            <div className="display-flex justify-content-between margin-top-20">
                {!showIncidentButton ? <div>&nbsp;</div> : <IncidentModal transportAssignment={transportAssignment} />}
                <button
                    type="button"
                    className={classNames(actionButtonStyles, isProcessingConfirmation ? 'btn-loading' : '')}
                    onClick={onConfirmTransportOrder}
                    disabled={isProcessingConfirmation || validation.isButtonDisabled}
                >
                    <span className={classNames(actionButtonIconStyles)} aria-hidden="true" />
                    <FormattedMessage id={getActionButtonLabelId(transportAssignment.status, isCapacityOrder)} />
                </button>
            </div>
        </>
    );
};
