import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import ExpanderList from '@rio-cloud/rio-uikit/ExpanderList';
import { useAppSelector } from '../../../configuration/setup/hooks';
import { getLocale } from '../../../configuration/lang/langSlice';

const headerClassName = 'text-size-18 text-color-highlight';
const bodyClassName = 'text-size-18';

// eslint-disable-next-line max-lines-per-function
export const GeneralFaq = (): React.ReactElement => {
    const supportFormUrlParams = new URLSearchParams();
    supportFormUrlParams.set('opener', window.location.origin);
    supportFormUrlParams.set('locale', useAppSelector(getLocale) ?? '');
    const supportFormUrl = `https://contact-form.rio.cloud/contact?${supportFormUrlParams.toString()}`;
    const supportMail = 'mailto:ams.ntt.data.service.desk@volkswagen.de';

    const userLanguage = useAppSelector(getLocale) ?? 'en';
    const languageForLinks = userLanguage.substring(0, 2);

    const faqListItems = [
        {
            header: <FormattedMessage id="welcomePage.faq.general.item0.question" />,
            headerClassName,
            body: (
                <FormattedMessage
                    id="welcomePage.faq.general.item0.answer"
                    values={{
                        _newLine: <br />,
                    }}
                />
            ),
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.general.item1.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.general.item1.answer" />,
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.general.item2.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.general.item2.answer" />,
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.general.item3.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.general.item3.answer" />,
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.general.item4.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.general.item4.answer" />,
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.general.item5.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.general.item5.answer" />,
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.general.item6.question" />,
            headerClassName,
            body: (
                <FormattedMessage
                    id="welcomePage.faq.general.item6.answer"
                    values={{
                        a: (chunks) => (
                            <a
                                href="https://www.odette.org/process/finished-vehicle-logistics"
                                rel="noreferrer noopener"
                                target="_blank"
                            >
                                {chunks}
                            </a>
                        ),
                    }}
                />
            ),
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.general.item7.question" />,
            headerClassName,
            body: (
                <FormattedMessage
                    id="welcomePage.faq.general.item7.answer"
                    values={{
                        _newLine: <br />,
                    }}
                />
            ),
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.general.item8.question" />,
            headerClassName,
            body: (
                <FormattedMessage
                    id="welcomePage.faq.general.item8.answer"
                    values={{
                        a: (chunks) => (
                            <a href={supportMail} rel="noreferrer noopener" target="_blank">
                                {chunks}
                            </a>
                        ),
                    }}
                />
            ),
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.general.item9.question" />,
            headerClassName,
            body: (
                <FormattedMessage
                    id="welcomePage.faq.general.item9.answer"
                    values={{
                        a: (chunks) => (
                            <a
                                href={`https://rio.cloud/${languageForLinks}/documents/outbound-order-book/privacy-policy`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {chunks}
                            </a>
                        ),
                    }}
                />
            ),
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.general.item10.question" />,
            headerClassName,
            body: (
                <FormattedMessage
                    id="welcomePage.faq.general.item10.answer"
                    values={{
                        a: (chunks) => (
                            <a href={supportFormUrl} rel="noreferrer noopener" target="_blank">
                                {chunks}
                            </a>
                        ),
                    }}
                />
            ),
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.general.item11.question" />,
            headerClassName,
            body: (
                <FormattedMessage
                    id="welcomePage.faq.general.item11.answer"
                    values={{
                        a: (chunks) => (
                            <a
                                href={`https://rio.cloud/${languageForLinks}/documents/outbound-order-book/dos/v06072023`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {chunks}
                            </a>
                        ),
                    }}
                />
            ),
            bodyClassName,
        },
    ];

    return <ExpanderList items={faqListItems} />;
};

// eslint-disable-next-line max-lines-per-function
export const InterfaceFaq = (): React.ReactElement => {
    const supportMail = 'mailto:ams.ntt.data.service.desk@volkswagen.de';

    const languageForApiLink = useAppSelector(getLocale)?.startsWith('de') === true ? 'de' : 'en';

    const faqListItems = [
        {
            header: <FormattedMessage id="welcomePage.faq.interface.item0.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.interface.item0.answer" />,
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.interface.item1.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.interface.item1.answer" />,
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.interface.item2.question" />,
            headerClassName,
            body: (
                <FormattedMessage
                    id="welcomePage.faq.interface.item2.answer"
                    values={{
                        a: (chunks) => (
                            <a
                                href="https://api-doc.finished-vehicles.outbound-order-book.rio.cloud/OOB-Security-Documentation.pdf"
                                rel="noreferrer noopener"
                                target="_blank"
                            >
                                {chunks}
                            </a>
                        ),
                    }}
                />
            ),
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.interface.item3.question" />,
            headerClassName,
            body: (
                <FormattedMessage
                    id="welcomePage.faq.interface.item3.answer"
                    values={{
                        _newLine: <br />,
                    }}
                />
            ),
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.interface.item4.question" />,
            headerClassName,
            body: (
                <FormattedMessage
                    id="welcomePage.faq.interface.item4.answer"
                    values={{
                        a: (chunks) => (
                            <a
                                href={`https://rio.cloud/${languageForApiLink}/apis/finished-vehicle-logistics`}
                                rel="noreferrer noopener"
                                target="_blank"
                            >
                                {chunks}
                            </a>
                        ),
                    }}
                />
            ),
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.interface.item5.question" />,
            headerClassName,
            body: (
                <FormattedMessage
                    id="welcomePage.faq.interface.item5.answer"
                    values={{
                        a: (chunks) => (
                            <a href={supportMail} rel="noreferrer noopener" target="_blank">
                                {chunks}
                            </a>
                        ),
                    }}
                />
            ),
            bodyClassName,
        },
        {
            header: <FormattedMessage id="welcomePage.faq.interface.item6.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.interface.item6.answer" />,
            bodyClassName,
        },
    ];

    return <ExpanderList items={faqListItems} />;
};

export const TransportOrderFaq = (): React.ReactElement => {
    // 5 was moved up after feedback
    const faqListItems = [0, 5, 1, 2, 3, 4, 6, 7, 8, 9].map((n) => {
        return {
            header: <FormattedMessage id={`welcomePage.faq.transportOrders.item${n}.question`} />,
            headerClassName,
            body: <FormattedMessage id={`welcomePage.faq.transportOrders.item${n}.answer`} />,
            bodyClassName,
        };
    });

    return <ExpanderList items={faqListItems} />;
};

export const ServiceOrderFaq = (): React.ReactElement => {
    const faqListItems = [
        {
            header: <FormattedMessage id="welcomePage.faq.serviceOrders.item0.question" />,
            headerClassName,
            body: <FormattedMessage id="welcomePage.faq.serviceOrders.item0.answer" />,
            bodyClassName,
        },
    ];

    return <ExpanderList items={faqListItems} />;
};

export const CompoundManagementFaq = () => {
    const faqListItems = [0, 1, 2, 3, 4, 5, 6, 7].map((n) => {
        return {
            header: <FormattedMessage id={`welcomePage.faq.compoundManagement.item${n}.question`} />,
            headerClassName,
            body: (
                <FormattedMessage
                    id={`welcomePage.faq.compoundManagement.item${n}.answer`}
                    values={{ _newLine: <br /> }}
                />
            ),
            bodyClassName,
        };
    });

    return <ExpanderList items={faqListItems} />;
};

export const TransportCapacityOrderFaq = () => {
    const faqListItems = [0, 1, 2, 3, 4].map((n) => {
        return {
            header: <FormattedMessage id={`welcomePage.faq.transportCapacityOrder.item${n}.question`} />,
            headerClassName,
            body: <FormattedMessage id={`welcomePage.faq.transportCapacityOrder.item${n}.answer`} />,
            bodyClassName,
        };
    });

    return <ExpanderList items={faqListItems} />;
};
