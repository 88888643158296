import { accessToken } from '../../../configuration/tokenHandling/accessToken';
import { AccessToken } from '../../../configuration/tokenHandling/tokenSlice';

export const prepareHeaders = (headers: Headers) => {
    headers.set('Content-Type', 'application/json');

    const token = accessToken.getAccessToken();
    if (token !== null) {
        headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
};
