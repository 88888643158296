import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../configuration/setup/store';
import { ServiceOrder, ServiceOrderCategory, ServiceOrderStatus } from '../serviceOrders/ServiceOrders.types';
import { ServiceOrdersTableColumns } from '../serviceOrders/table/ServiceOrdersTableColumns';
import { SortDirection, TableSort } from '../sharedComponents/table/SortedTableHeader';
import { ServiceOrderFilters, ServiceOrderModalFilters } from '../serviceOrders/filter.types';

export const NO_SELECTED_SERVICE_ORDER = undefined;

export type ServiceOrderSorting = { [key in ServiceOrderCategory]: TableSort<ServiceOrdersTableColumns> };

export type ServiceOrdersState = {
    selectedServiceOrder: ServiceOrder | undefined;
    filters: ServiceOrderFilters;
    search: string;
    sorting: ServiceOrderSorting;
    category: ServiceOrderCategory;
};

export const initialSortingState: ServiceOrderSorting = {
    [ServiceOrderCategory.CURRENT]: {
        dir: SortDirection.DESCENDING,
        column: ServiceOrdersTableColumns.EXECUTION_DATE,
    },
    [ServiceOrderCategory.ARCHIVE]: {
        dir: SortDirection.DESCENDING,
        column: ServiceOrdersTableColumns.EXECUTION_DATE,
    },
};

export const initialServiceOrdersState: ServiceOrdersState = {
    selectedServiceOrder: NO_SELECTED_SERVICE_ORDER,
    filters: {
        statusFilter: [],
        modalFilters: { startDateFilter: undefined, endDateFilter: undefined, serviceTypeFilter: [] },
    },
    search: '',
    sorting: initialSortingState,
    category: ServiceOrderCategory.CURRENT,
};

export const serviceOrdersSlice = createSlice({
    name: 'serviceOrders',
    initialState: initialServiceOrdersState,
    reducers: {
        selectServiceOrder: (state: ServiceOrdersState, action: PayloadAction<ServiceOrder>) => {
            state.selectedServiceOrder = action.payload;
        },
        deselectServiceOrder: (state: ServiceOrdersState) => {
            state.selectedServiceOrder = NO_SELECTED_SERVICE_ORDER;
        },
        setCategory: (state: ServiceOrdersState, action: PayloadAction<ServiceOrderCategory>) => {
            state.category = action.payload;
        },
        setServiceOrderStatusFilter: (state: ServiceOrdersState, action: PayloadAction<ServiceOrderStatus[]>) => {
            state.filters.statusFilter = action.payload;
        },
        setModalFilters: (state: ServiceOrdersState, action: PayloadAction<ServiceOrderModalFilters>) => {
            state.filters.modalFilters = action.payload;
        },
        setSearchTerm: (state: ServiceOrdersState, action: PayloadAction<string>) => {
            state.search = action.payload;
        },
        setSortingForActiveCategory: (
            state: ServiceOrdersState,
            action: PayloadAction<{
                sort: TableSort<ServiceOrdersTableColumns>;
            }>,
        ) => {
            state.sorting[state.category] = action.payload.sort;
        },
        setInitialState: () => initialServiceOrdersState,
    },
});

export const getSelectedServiceOrder = (state: RootState) => state.portal.serviceOrders.selectedServiceOrder;
export const getServiceOrderStatusFilter = (state: RootState) => state.portal.serviceOrders.filters.statusFilter;
export const getServiceOrderModalFilters = (state: RootState): ServiceOrderModalFilters =>
    state.portal.serviceOrders.filters.modalFilters;
export const getServiceOrderSearch = (state: RootState) => state.portal.serviceOrders.search;
export const getServiceOrderSort = (state: RootState) =>
    state.portal.serviceOrders.sorting[state.portal.serviceOrders.category];
export const getServiceOrderCategory = (state: RootState) => state.portal.serviceOrders.category;
