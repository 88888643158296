import moment, { Moment } from 'moment';
import { Dispatch, SetStateAction, useId } from 'react';
import { FormattedMessage } from 'react-intl';
import { FilterModalDatePicker } from '../../../sharedComponents/filter/FilterModal';
import { ServiceType } from '../../serviceType.types';
import { ServiceTypeFilter } from './ServiceTypeFilter';

export type LocalModalFilters = {
    startDateFilter: Date | string | undefined;
    endDateFilter: Date | string | undefined;
    serviceTypeFilter: ServiceType[];
};

type ServiceOrderFilterModalContentProps = {
    localFilters: LocalModalFilters;
    setLocalFilters: Dispatch<SetStateAction<LocalModalFilters>>;
    hasStartDateTimeError: boolean;
    hasEndDateTimeError: boolean;
    hasTimeRangeOrderError: boolean;
};

export const ServiceOrderFilterModalContent = ({
    localFilters,
    setLocalFilters,
    hasStartDateTimeError,
    hasEndDateTimeError,
    hasTimeRangeOrderError,
}: ServiceOrderFilterModalContentProps) => {
    const nextDateValue = (date: Moment | string | undefined) =>
        moment.isMoment(date) ? date.toDate() : date === '' ? undefined : date;
    const startDateId = useId();
    const endDateId = useId();

    return (
        <div className="margin-bottom-20">
            <label className="margin-bottom-10">
                <FormattedMessage id="outboundPortal.serviceOrders.table.toolbar.filter.orderIssuedDate" />
            </label>
            <div className="display-flex justify-content-start row">
                <div className="col-6 padding-right-5">
                    <FilterModalDatePicker
                        id={startDateId}
                        labelId="outboundPortal.common.table.from"
                        hasFormatError={hasStartDateTimeError}
                        hasTimeRangeOrderError={false}
                        value={localFilters.startDateFilter}
                        onChange={(date: Moment | string | undefined) =>
                            setLocalFilters((prevState) => ({ ...prevState, startDateFilter: nextDateValue(date) }))
                        }
                    />
                </div>
                <div className="col-6 padding-left-5">
                    <FilterModalDatePicker
                        id={endDateId}
                        labelId="outboundPortal.common.table.to"
                        hasFormatError={hasEndDateTimeError}
                        hasTimeRangeOrderError={hasTimeRangeOrderError}
                        value={localFilters.endDateFilter}
                        onChange={(date: Moment | string | undefined) =>
                            setLocalFilters((prevState) => ({ ...prevState, endDateFilter: nextDateValue(date) }))
                        }
                    />
                </div>
            </div>
            <hr className="margin-top-20 margin-bottom-20" />
            <div>
                <ServiceTypeFilter
                    value={localFilters.serviceTypeFilter}
                    onChange={(serviceTypeFilter) =>
                        setLocalFilters((prevState) => ({ ...prevState, serviceTypeFilter }))
                    }
                />
            </div>
        </div>
    );
};
