import { fromEnum } from '../../responseUtil';
import * as t from 'io-ts';
import { VehicleIdCodec } from '../vehicleApi.types';
import { NonEmptyString } from 'io-ts-types';
import { ApiAccessoryType, CollectedAccessoriesCodec } from './accessoriesApi.types';
import { ApiServiceType } from './serviceTypesApi.type';
import { ProblemWithErrorCodeCodec } from '../problemApi.types';

export enum ApiServiceOrderStatus {
    OPEN = 'open',
    COMPLETED = 'completed',
    CANCELLED = 'cancelled',
}

export enum ServiceOrderApiSort {
    STATUS_ASC = '+status',
    STATUS_DES = '-status',
    COMPLETED_AT_DESC = '-completed_at',
    COMPLETED_AT_ASC = '+completed_at',
    TYPE_ASC = '+type',
    TYPE_DESC = '-type',
    ORDER_ISSUED_AT_ASC = '+order_issued_at',
    ORDER_ISSUED_AT_DESC = '-order_issued_at',
}

const ServiceOrderQueryParameterCodec = t.partial({
    statuses: t.array(fromEnum<ApiServiceOrderStatus>('ApiServiceOrderStatus', ApiServiceOrderStatus)),
    service_types: t.array(fromEnum<ApiServiceType>('ApiServiceType', ApiServiceType)),
    order_issued_at_after: t.string,
    order_issued_at_before: t.string,
    archived: t.boolean,
    q: t.string,
    sort: fromEnum<ServiceOrderApiSort>('ServiceOrderApiSort', ServiceOrderApiSort),
    limit: t.number,
});

const ServiceOrderExcelQueryParameterCodec = t.partial({
    statuses: t.array(fromEnum<ApiServiceOrderStatus>('ApiServiceOrderStatus', ApiServiceOrderStatus)),
    service_types: t.array(fromEnum<ApiServiceType>('ApiServiceType', ApiServiceType)),
    order_issued_at_after: t.string,
    order_issued_at_before: t.string,
});

const ServiceOrderVehicleModelCodec = t.intersection([
    t.type({
        name: t.string,
    }),
    t.partial({
        group: t.string,
        version: t.string,
        manufacturer: t.string,
    }),
]);

export type ApiServiceOrderVehicleModel = t.TypeOf<typeof ServiceOrderVehicleModelCodec>;

const ServiceOrderVehicleCodec = t.intersection([
    t.type({
        id: VehicleIdCodec,
        requested_accessories: t.array(fromEnum<ApiAccessoryType>('ApiServiceAccessories', ApiAccessoryType)),
    }),
    t.partial({
        model: ServiceOrderVehicleModelCodec,
        collected_accessories: CollectedAccessoriesCodec,
    }),
]);

export type ApiServiceOrderVehicle = t.TypeOf<typeof ServiceOrderVehicleCodec>;

const ServiceOrderCodec = t.intersection([
    t.type({
        id: NonEmptyString,
        external_order_id: NonEmptyString,
        vehicle: ServiceOrderVehicleCodec,
        status: fromEnum<ApiServiceOrderStatus>('ApiServiceOrderStatus', ApiServiceOrderStatus),
        type: fromEnum<ApiServiceType>('ApiServiceType', ApiServiceType),
        time_zone: t.string,
        has_unacknowledged_external_changes: t.boolean,
        is_finalized: t.boolean,
        is_archived: t.boolean,
        order_issued_at: t.string,
    }),
    t.partial({
        completed_at: t.string,
        general_information: t.string,
        description: t.string,
    }),
]);

export const ExcelExportTooLargeProblemCodec = t.intersection([
    ProblemWithErrorCodeCodec,
    t.type({
        error_code: t.literal('result-set-too-large'),
    }),
]);

export type ApiServiceOrder = t.TypeOf<typeof ServiceOrderCodec>;

export const ServiceOrderPageCodec = t.type({
    items: t.array(ServiceOrderCodec),
    has_more: t.boolean,
});

export type ApiServiceOrderPage = t.TypeOf<typeof ServiceOrderPageCodec>;
export type ApiServiceOrderQueryParameter = t.TypeOf<typeof ServiceOrderQueryParameterCodec>;
export type ApiServiceOrderExcelQueryParameter = t.TypeOf<typeof ServiceOrderExcelQueryParameterCodec>;
