import { useCallback, useRef, useState } from 'react';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import { FormattedMessage } from 'react-intl';
import { SO_EXCEL_DOWNLOAD_BUTTON } from './ServiceOrderTableToolbar';
import { ServiceOrderExportModalContent, UNSET_FILTERS } from './ServiceOrderExportModalContent';
import { DateInput, validateDateTimeFields } from '../../../sharedComponents/dateHelper';
import { useGetServiceOrderExcelMutation } from '../../../api/serviceOrdersApi';
import { runInBackground } from '../../../../../configuration/setup/backgroundActions';
import { isDate } from 'date-fns';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import { handleQueryError, isErrorResponse } from '../../../notifications/ErrorNotification';
import { ExcelExportTooLargeProblemCodec } from '../../../api/types/serviceOrder/serviceOrdersApi.types';
import Button from '@rio-cloud/rio-uikit/Button';
import classNames from 'classnames';
import { triggerBrowserDownload } from '../../../downloadHelper';
import {
    FetchBaseQueryError,
    MutationActionCreatorResult,
    MutationDefinition,
    ResultTypeFrom,
} from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { trace } from '../../../../../configuration/setup/trace';

export const FALL_BACK_FILE_NAME = 'service-order-export.xlsx';

const discardInvalidDateInput = (date: DateInput) => {
    return isDate(date) ? (date as Date) : undefined;
};

const ServiceOrderExportModalFooter = ({
    isLoading,
    downloadDisabled,
    handleDownload,
    handleCancel,
    resetFilters,
}: {
    isLoading: boolean;
    downloadDisabled: boolean;
    handleCancel: () => void;
    handleDownload: () => void;
    resetFilters: () => void;
}) => (
    <div className="display-flex justify-content-between">
        <Button
            iconName="rioglyph-revert"
            bsStyle={Button.PRIMARY}
            variant={Button.VARIANT_LINK}
            onClick={resetFilters}
        >
            <FormattedMessage id="outboundOrderBook.common.table.toolbar.filter.reset" />
        </Button>
        <div className="display-flex btn-toolbar">
            <Button onClick={handleCancel}>
                <FormattedMessage id="outboundPortal.cancel" />
            </Button>
            <Button
                className={classNames(isLoading ? 'btn-loading' : '')}
                disabled={downloadDisabled || isLoading}
                iconName="rioglyph-download"
                bsStyle={Button.PRIMARY}
                onClick={handleDownload}
            >
                <FormattedMessage id="outboundOrderBook.serviceOrders.export.title" />
            </Button>
        </div>
    </div>
);

const ErrorAlert = (props: { hideAlert: () => void }) => {
    return (
        <div className="alert alert-dismissible alert-danger margin-bottom-20">
            <button
                type="button"
                className="btn btn-icon-only close"
                data-dismiss="alert"
                onClick={props.hideAlert}
                data-testid="close-alert"
            >
                <span className="rioglyph rioglyph-remove" />
            </button>
            <div className="display-flex gap-10">
                <span className="text-color-danger text-size-h4 rioglyph rioglyph rioglyph-error-sign" />
                <div>
                    <strong className="text-size-16">
                        <FormattedMessage id="outboundOrderBook.serviceOrders.export.tooLargeError.heading" />
                    </strong>
                    <div className="margin-y-5">
                        <FormattedMessage
                            id="outboundOrderBook.serviceOrders.export.tooLargeError.description"
                            values={{ maxSize: 20000 }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

// eslint-disable-next-line max-lines-per-function
export const ServiceOrderExportModal = () => {
    const [showExportModal, setShowExportModal] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [localFilters, setLocalFilters] = useState(UNSET_FILTERS);
    const linkEl = useRef<HTMLAnchorElement>(null);
    const [getExcel] = useGetServiceOrderExcelMutation();

    const { downloadIsActive, startDownload, cancelDownload } = useDownload({
        initDownload: () =>
            getExcel({
                statuses: localFilters.statusFilter,
                serviceTypes: localFilters.serviceTypeFilter,
                orderIssuedAtAfter: discardInvalidDateInput(localFilters.startDateFilter),
                orderIssuedAtBefore: discardInvalidDateInput(localFilters.endDateFilter),
            }),
        onError: (error) => {
            if (isErrorResponse(error, ExcelExportTooLargeProblemCodec)) {
                setShowErrorAlert(true);
            } else {
                handleQueryError(error);
            }
        },
        onDownloadFinished: (file) => {
            triggerBrowserDownload(linkEl, file, FALL_BACK_FILE_NAME);
            setShowErrorAlert(false);
        },
    });

    const handleOnClick = () => setShowExportModal(true);

    const resetFilters = () => setLocalFilters(UNSET_FILTERS);

    const handleCancel = () => {
        cancelDownload();
        setShowExportModal(false);
    };

    const { hasStartDateTimeError, hasEndDateTimeError, hasTimeRangeOrderError } = validateDateTimeFields(
        localFilters.startDateFilter,
        localFilters.endDateFilter,
    );

    return (
        <>
            <OverlayTrigger
                placement="bottom-end"
                overlay={
                    <Tooltip id="tooltip" allowOnTouch>
                        <FormattedMessage id="outboundOrderBook.serviceOrders.export.tooltip" />
                    </Tooltip>
                }
            >
                <button
                    type="button"
                    onClick={handleOnClick}
                    className="btn btn-default btn-icon-only"
                    data-testid={SO_EXCEL_DOWNLOAD_BUTTON}
                >
                    <span className="rioglyph rioglyph-download" aria-hidden="true" />
                </button>
            </OverlayTrigger>
            <Dialog
                show={showExportModal}
                bsSize={Dialog.SIZE_MD}
                title={<FormattedMessage id="outboundOrderBook.serviceOrders.export.title" />}
                body={
                    <>
                        {showErrorAlert ? <ErrorAlert hideAlert={() => setShowErrorAlert(false)} /> : undefined}
                        <ServiceOrderExportModalContent
                            localFilters={localFilters}
                            setLocalFilters={setLocalFilters}
                            hasStartDateTimeError={hasStartDateTimeError}
                            hasEndDateTimeError={hasEndDateTimeError}
                            hasTimeRangeOrderError={hasTimeRangeOrderError}
                        />
                    </>
                }
                footer={
                    <ServiceOrderExportModalFooter
                        handleCancel={handleCancel}
                        handleDownload={startDownload}
                        isLoading={downloadIsActive}
                        downloadDisabled={hasTimeRangeOrderError || hasStartDateTimeError || hasEndDateTimeError}
                        resetFilters={resetFilters}
                    />
                }
                onClose={handleCancel}
            />
            <a ref={linkEl} href="" target="_blank" className="display-none" aria-hidden="true" />
        </>
    );
};

const useDownload = <D extends MutationDefinition<any, any, any, any>>({
    initDownload,
    onError,
    onDownloadFinished,
}: {
    initDownload: () => MutationActionCreatorResult<D>;
    onError: (error: FetchBaseQueryError | SerializedError) => void;
    onDownloadFinished: (file: ResultTypeFrom<D>) => void;
}) => {
    const [downloadIsActive, setDownloadIsActive] = useState(false);
    const ongoingDownload = useRef<MutationActionCreatorResult<D> | null>(null);

    const resetState = useCallback(() => {
        setDownloadIsActive(false);
        ongoingDownload.current = null;
    }, [setDownloadIsActive, ongoingDownload]);

    const startDownload = useCallback(() => {
        if (ongoingDownload.current !== null) {
            return;
        }

        const download = initDownload();
        ongoingDownload.current = download;

        setDownloadIsActive(true);

        runInBackground(async () => {
            const { data, error } = await download;

            if (error !== undefined && 'name' in error && error.name === 'AbortError') {
                trace('Download was aborted');
            } else if (error !== undefined) {
                onError(error);
            } else if (data !== undefined) {
                onDownloadFinished(data);
            }

            resetState();
        });
    }, [setDownloadIsActive, ongoingDownload, initDownload, onError, onDownloadFinished, resetState]);

    const cancelDownload = useCallback(() => {
        ongoingDownload.current?.abort();
        resetState();
    }, [ongoingDownload, resetState]);

    return {
        downloadIsActive,
        startDownload,
        cancelDownload,
    };
};
