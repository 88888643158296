import { useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/hooks';
import { validateDateTimeFields } from '../../../sharedComponents/dateHelper';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import { FormattedMessage } from 'react-intl';
import { LocalModalFilters, ServiceOrderFilterModalContent } from './ServiceOrderFilterModalContent';
import { FilterModalButton, FilterModalFooter } from '../../../sharedComponents/filter/FilterModal';
import { isDate } from 'date-fns';
import { getServiceOrderModalFilters, serviceOrdersSlice } from '../../../reducers/serviceOrdersSlice';

const discardInvalidDateInputs = (localFilters: LocalModalFilters) => ({
    startDateFilter: isDate(localFilters.startDateFilter) ? (localFilters.startDateFilter as Date) : undefined,
    endDateFilter: isDate(localFilters.endDateFilter) ? (localFilters.endDateFilter as Date) : undefined,
});

const UNSET_FILTERS: LocalModalFilters = {
    startDateFilter: undefined,
    endDateFilter: undefined,
    serviceTypeFilter: [],
};

export const ServiceOrderFilterModal = () => {
    const dispatch = useAppDispatch();
    const modalFilters = useAppSelector(getServiceOrderModalFilters);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [localFilters, setLocalFilters] = useState<LocalModalFilters>(UNSET_FILTERS);

    const isFilterButtonActive =
        modalFilters.startDateFilter !== undefined ||
        modalFilters.endDateFilter !== undefined ||
        modalFilters.serviceTypeFilter.length > 0;

    const handleOnClickApply = () => {
        setLocalFilters({
            ...localFilters,
        });
        dispatch(
            serviceOrdersSlice.actions.setModalFilters({
                ...discardInvalidDateInputs(localFilters),
                serviceTypeFilter: localFilters.serviceTypeFilter,
            }),
        );
        setShowFilterModal(false);
    };

    const handleOnClickCancel = () => {
        setLocalFilters(modalFilters);
        setShowFilterModal(false);
    };

    const resetFilters = () => {
        setLocalFilters(UNSET_FILTERS);
    };

    const { hasStartDateTimeError, hasEndDateTimeError, hasTimeRangeOrderError } = useMemo(
        () => validateDateTimeFields(localFilters.startDateFilter, localFilters.endDateFilter),
        [localFilters],
    );

    return (
        <>
            <FilterModalButton isFilterActive={isFilterButtonActive} setShowFilterModal={setShowFilterModal} />
            <Dialog
                show={showFilterModal}
                bsSize={Dialog.SIZE_SM}
                title={<FormattedMessage id="outboundOrderBook.common.table.toolbar.filter.title" />}
                body={
                    <ServiceOrderFilterModalContent
                        localFilters={localFilters}
                        setLocalFilters={setLocalFilters}
                        hasStartDateTimeError={hasStartDateTimeError}
                        hasEndDateTimeError={hasEndDateTimeError}
                        hasTimeRangeOrderError={hasTimeRangeOrderError}
                    />
                }
                footer={
                    <FilterModalFooter
                        handleOnClickApply={handleOnClickApply}
                        handleOnClickCancel={handleOnClickCancel}
                        resetFilters={resetFilters}
                        isApplyButtonDisabled={hasTimeRangeOrderError || hasStartDateTimeError || hasEndDateTimeError}
                    />
                }
                onClose={handleOnClickCancel}
            />
        </>
    );
};
