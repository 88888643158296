import { FunctionComponent } from 'react';
import { VehicleDetailsHeader } from '../../sharedComponents/sidebar/VehicleDetailsHeader';
import { DetailsTableRow } from '../../sharedComponents/sidebar/DetailsTableRow';
import { DetailsTable } from '../../sharedComponents/sidebar/DetailsTable';
import { ServiceOrderVehicleModel } from '../ServiceOrders.types';

type ModelDetailsProps = {
    model: ServiceOrderVehicleModel | undefined;
};
export const ModelDetails: FunctionComponent<ModelDetailsProps> = ({ model }) => {
    if (model === undefined) {
        return <div />;
    }
    return (
        <div className="panel panel-default margin-bottom-0 margin-20">
            <div className="panel-heading margin-x-15">
                <VehicleDetailsHeader
                    messageId="outboundOrderBook.common.heading.model"
                    model={model}
                    isCancelled={false}
                />
            </div>
            <div className="panel-body">
                <DetailsTable>
                    <DetailsTableRow
                        messageId="outboundOrderBook.common.sidebar.details.vehicle.model.modelGroup"
                        value={model.group}
                    />
                    <DetailsTableRow
                        messageId="outboundOrderBook.common.sidebar.details.vehicle.model.modelVersion"
                        value={model.version}
                    />
                </DetailsTable>
            </div>
        </div>
    );
};
