import Button from '@rio-cloud/rio-uikit/Button';
import { FunctionComponent } from 'react';
import { assertExhaustive } from '../../../../../utils';
import { NoValue } from '../../../sharedComponents/NoValue';
import { DateTime } from '../../../sharedComponents/table/BaseTableComponents';
import { InventoryVehicle } from '../../compoundManagement.types';
import { DisplayCancellableData } from '../../shared/dataDisplay/DisplayCancellableData';
import { DisplayDispatchType } from '../../shared/dataDisplay/DisplayDispatchType';
import { DisplayFreightForwarder } from '../../shared/dataDisplay/DisplayFreightForwarder';
import { DisplayFuelType } from '../../shared/dataDisplay/DisplayFuelType';
import { DisplayModelName } from '../../shared/dataDisplay/DisplayModelName';
import { CompoundVehicleActionButton } from '../../table/CompoundVehicleActionButton';
import { VehicleInventoryTableColumn } from './VehicleInventoryTableColumn';

type TableDataContentProps = {
    column: VehicleInventoryTableColumn;
    vehicle: InventoryVehicle;
};

export const VehicleInventoryTableDataContent: FunctionComponent<TableDataContentProps> = ({ vehicle, column }) => {
    const { isCancelled, isFinalized } = vehicle.arrival;
    const spanOrNoValue = (text: string | undefined) => (Boolean(text) ? <span>{text}</span> : <NoValue />);

    switch (column) {
        case VehicleInventoryTableColumn.VIN:
            return <div className="ellipsis-1">{vehicle.vehicle.vin}</div>;
        case VehicleInventoryTableColumn.LOCATION_ID:
            return <span>{vehicle.compoundLocation.identifier}</span>;
        case VehicleInventoryTableColumn.LOCATION_CITY:
            return <span>{vehicle.compoundLocation.address.city}</span>;
        case VehicleInventoryTableColumn.MANUFACTURER:
            return spanOrNoValue(vehicle.vehicle.model.manufacturer);
        case VehicleInventoryTableColumn.MODEL:
            return <DisplayModelName vehicle={vehicle.vehicle} />;
        case VehicleInventoryTableColumn.DISPATCH_TYPE:
            return <DisplayDispatchType vehicle={vehicle.vehicle} />;
        case VehicleInventoryTableColumn.FUEL_TYPE:
            return <DisplayFuelType vehicle={vehicle.vehicle} />;
        case VehicleInventoryTableColumn.MODEL_VERSION:
            return spanOrNoValue(vehicle.vehicle.model.version);
        case VehicleInventoryTableColumn.ARRIVAL_DATE:
            return (
                <DisplayCancellableData isCancelled={isCancelled}>
                    <DateTime
                        dateTime={vehicle.arrival.arrivalCompletedAt}
                        timeZone={vehicle.compoundLocation.timeZone}
                    />
                </DisplayCancellableData>
            );
        case VehicleInventoryTableColumn.ARRIVAL_FREIGHT_FORWARDER:
            return (
                <DisplayCancellableData isCancelled={isCancelled}>
                    <DisplayFreightForwarder
                        freightForwarder={vehicle.arrival.freightForwarder}
                        className="ellipsis-1"
                    />
                </DisplayCancellableData>
            );
        case VehicleInventoryTableColumn.ACTION:
            return (
                isCancelled ||
                isFinalized || (
                    <CompoundVehicleActionButton
                        labelId="outboundPortal.compoundManagement.button.updateArrivalOrDeparture"
                        icon="rioglyph-refresh"
                        vehicle={vehicle}
                        buttonStyle={Button.DEFAULT}
                    />
                )
            );
        default:
            assertExhaustive(column);
    }
};
