import { FunctionComponent } from 'react';
import { LabelledInfoCell } from '../../../sharedComponents/sidebar/LabelledInfoCell';
import { CompoundLocation, Departure } from '../../compoundManagement.types';
import { DisplayFreightForwarder } from '../../shared/dataDisplay/DisplayFreightForwarder';
import { DepartureCompletedForm } from '../../shared/sidebar/DepartureCompletedForm';

export type DepartureDetailsProps = {
    compoundVehicleId: string;
    departure: Departure;
    compoundLocation: CompoundLocation;
};

export const DepartureDetails: FunctionComponent<DepartureDetailsProps> = ({
    compoundVehicleId,
    departure,
    compoundLocation,
}) => {
    return (
        <DepartureCompletedForm
            timeZone={compoundLocation.timeZone}
            compoundVehicleId={compoundVehicleId}
            disabled={departure.isFinalized}
            departureCompletedAt={departure.departureCompletedAt}
        >
            <LabelledInfoCell labelMessageId="outboundOrderBook.compoundManagement.table.heading.carrier">
                <DisplayFreightForwarder freightForwarder={departure.freightForwarder} />
            </LabelledInfoCell>
        </DepartureCompletedForm>
    );
};
