import React, { useState } from 'react';
import { ThankYou } from './registationForm/ThankYou';
import { RegistrationForm } from './registationForm/RegistrationForm';
import { FormattedMessage } from 'react-intl';
import Button from '@rio-cloud/rio-uikit/Button';
import { usePostActivationRequestMutation } from '../api/activationApi';
import classNames from 'classnames';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { RegistrationInputs } from './registration.types';
import { handleQueryError } from '../notifications/ErrorNotification';
import Dialog from '@rio-cloud/rio-uikit/Dialog';

const Footer = (props: { showThankYou: boolean; onClickCancel: () => void; formId: string; isLoading: boolean }) => {
    const spinnerButtonClassnames = props.isLoading ? 'btn-loading disabled' : '';

    return (
        <div className="pull-right btn-toolbar">
            <Button onClick={props.onClickCancel} className="btn btn-default">
                <FormattedMessage id={props.showThankYou ? 'outboundPortal.close' : 'outboundPortal.cancel'} />
            </Button>
            {!props.showThankYou && (
                <Button
                    type="submit"
                    form={props.formId}
                    className={classNames('btn-primary', spinnerButtonClassnames)}
                >
                    <FormattedMessage id="outboundPortal.submit" />
                </Button>
            )}
        </div>
    );
};

export const RegistrationFormDialog = (props: {
    show: boolean;
    setShow: (newValue: boolean) => void;
}): React.ReactElement => {
    const [requestActivation, request] = usePostActivationRequestMutation();
    const [showThankYou, setShowThankYou] = useState(false);
    const formId = 'regForm';

    const closeModal = () => {
        props.setShow(false);
        setShowThankYou(false);
    };

    const onSubmitHandler: SubmitHandler<RegistrationInputs> = async (data: RegistrationInputs) => {
        await requestActivation(data)
            .unwrap()
            .then(() => setShowThankYou(true))
            .catch(handleQueryError);
    };

    const body = showThankYou ? <ThankYou /> : <RegistrationForm formId={formId} onSubmitHandler={onSubmitHandler} />;

    return (
        <Dialog
            show={props.show}
            title={<FormattedMessage id="outboundPortal.activation.title" />}
            body={body}
            showCloseButton
            onClose={closeModal}
            bsSize={Dialog.SIZE_LG}
            footer={
                <Footer
                    onClickCancel={closeModal}
                    formId={formId}
                    showThankYou={showThankYou}
                    isLoading={request.isLoading}
                />
            }
        />
    );
};
