import Dialog from '@rio-cloud/rio-uikit/Dialog';
import Notification from '@rio-cloud/rio-uikit/Notification';
import SimpleTooltip from '@rio-cloud/rio-uikit/SimpleTooltip';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import useClipboard from '@rio-cloud/rio-uikit/useClipboard';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { NoValue } from '../../../sharedComponents/NoValue';
import { Contact } from '../../transportAssignment.types';

type ContactDetailsProps = {
    readonly contact: Contact;
};

export const ContactDetails = ({ contact }: ContactDetailsProps) => {
    const clipboard = useClipboard();
    const [showContactDetailsDialog, setShowContactDetailsDialog] = useState(false);
    const copyEmailButtonDisabled = contact.email === undefined;

    useEffect(() => {
        if (clipboard.copied) {
            Notification.success(
                <FormattedMessage id="outboundPortal.transportAssignments.sidebar.assignmentInformation.contactDetails.emailCopySuccess" />,
            );
        }
    }, [clipboard.copied]);

    const contactDetailsDialogBody = (
        <div className="text-size-16">
            <div className="text-medium">
                <FormattedMessage id="outboundPortal.transportOrders.details.body.details.transportOrder.contact.name" />
            </div>
            <div className="margin-bottom-10">{contact.name}</div>
            <div className="text-medium">
                <FormattedMessage id="outboundPortal.transportOrders.details.body.details.transportOrder.contact.phone" />
            </div>
            <div className="margin-bottom-10">{contact.phone ?? <NoValue />}</div>
            <div className="text-medium">
                <FormattedMessage id="outboundPortal.transportOrders.details.body.details.transportOrder.contact.email" />
            </div>
            <div>{contact.email ?? <NoValue />}</div>
        </div>
    );

    const copyEmailButton = (
        <button
            type="button"
            className="btn btn-primary btn-link btn-icon-right"
            onClick={() => contact.email && clipboard.copy(contact.email)}
            disabled={copyEmailButtonDisabled}
        >
            <span className="rioglyph rioglyph-duplicate" aria-hidden="true" />
            <span className="text-capitalize">
                <FormattedMessage id="outboundPortal.transportOrders.details.body.details.transportOrder.contact.email" />
            </span>
        </button>
    );

    return (
        <div>
            <button
                type="button"
                className="btn btn-primary btn-link padding-left-0"
                onClick={() => setShowContactDetailsDialog(true)}
            >
                <span className="rioglyph rioglyph-user" aria-hidden="true" />
                <span className="text-capitalize">
                    <FormattedMessage id="outboundPortal.transportAssignments.sidebar.assignmentInformation.contactDetails" />
                </span>
            </button>
            <span className="text-color-light">|</span>
            {copyEmailButtonDisabled ? (
                <SimpleTooltip
                    content={
                        <FormattedMessage id="outboundPortal.transportOrders.details.body.details.transportOrder.contact.emailMissing" />
                    }
                    placement={Tooltip.RIGHT}
                >
                    <span>{copyEmailButton}</span>
                </SimpleTooltip>
            ) : (
                copyEmailButton
            )}

            <Dialog
                show={showContactDetailsDialog}
                title={
                    <FormattedMessage id="outboundPortal.transportAssignments.sidebar.assignmentInformation.contactDetails.header" />
                }
                footer={null}
                body={contactDetailsDialogBody}
                bsSize={Dialog.SIZE_SM}
                onClose={() => setShowContactDetailsDialog(false)}
                showCloseButton
            />
        </div>
    );
};
