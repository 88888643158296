import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import Sidebar from '@rio-cloud/rio-uikit/Sidebar';
import { getSelectedServiceOrder, serviceOrdersSlice } from '../../reducers/serviceOrdersSlice';
import { useEffect, useState } from 'react';
import { getObjectKeyForAccessory, getServiceNameByType } from '../serviceOrderUtils';
import { ServiceOrderSidebarHeader } from './ServiceOrderSidebarHeader';
import { ServiceOrderSidebarBody } from './ServiceOrderSidebarBody';
import { AccessoryType, CollectedAccessories } from '../accessory.types';
import {
    usePutAcknowledgeServiceOrderChangesMutation,
    usePutArchiveServiceOrderMutation,
    usePutCompleteServiceOrderMutation,
} from '../../api/serviceOrdersApi';
import { FooterButtons } from './ServiceOrderSidebarFooter';
import { showSuccessNotification } from '../../notifications/SuccessNotification';
import { handleQueryError } from '../../notifications/ErrorNotification';
import { DateInput, dateMinusSeconds, isValidDate } from '../../sharedComponents/dateHelper';
import isEmpty from 'lodash/isEmpty';
import { ServiceOrder } from '../ServiceOrders.types';
import { GRACE_PERIOD_IN_SECONDS_COMPLETION_DATE } from '../../sharedComponents/commonConfig';
import { runInBackground, runInBackgroundCallback } from '../../../../configuration/setup/backgroundActions';

export type ServiceOrderSidebarCompletion = {
    accessories: CollectedAccessories;
    generalInformation?: string;
    completedAt: DateInput;
};

export type SidebarFormFieldError<T> = { type: T };
export type CompletedAtErrorType = 'invalidDate' | 'dateInFuture' | 'completedAtBeforeOrderIssuedAt';
export type AccessoryTypeErrorType = 'invalidValue';
export type SidebarFormErrors = { [key in AccessoryType]?: SidebarFormFieldError<AccessoryTypeErrorType> } & {
    completedAt?: SidebarFormFieldError<CompletedAtErrorType>;
};

export const ServiceOrderSidebar = () => {
    const selectedServiceOrder = useAppSelector(getSelectedServiceOrder);
    return <ServiceOrderSidebarInternal selectedServiceOrder={selectedServiceOrder} key={selectedServiceOrder?.id} />;
};

// eslint-disable-next-line max-lines-per-function
const ServiceOrderSidebarInternal = ({ selectedServiceOrder }: { selectedServiceOrder: ServiceOrder | undefined }) => {
    const dispatch = useAppDispatch();
    const [completion, setCompletion] = useState<ServiceOrderSidebarCompletion>({
        accessories: selectedServiceOrder?.vehicle.collectedAccessories ?? {},
        generalInformation: selectedServiceOrder?.generalInformation,
        completedAt: selectedServiceOrder?.completedAt,
    });
    const [completeServiceOrder, completionRequest] = usePutCompleteServiceOrderMutation();
    const [acknowledgeChanges] = usePutAcknowledgeServiceOrderChangesMutation();
    const [archiveServiceOrder, archiveRequest] = usePutArchiveServiceOrderMutation();
    const [formErrors, setFormErrors] = useState<SidebarFormErrors>({});

    const clearFormErrors = () => {
        setFormErrors({});
    };

    const closeSidebar = () => {
        dispatch(serviceOrdersSlice.actions.deselectServiceOrder());
    };

    useEffect(() => {
        return () => {
            if (selectedServiceOrder !== undefined && selectedServiceOrder.hasUnacknowledgedExternalChanges) {
                runInBackground(
                    acknowledgeChanges({
                        serviceOrderId: selectedServiceOrder.id,
                    }),
                );
            }
        };
    }, [acknowledgeChanges, selectedServiceOrder]);

    const sidebarProperties = {
        showHeaderBorder: true,
        resizable: true,
        position: Sidebar.RIGHT,
        minWidth: 400,
        maxWidth: 550,
        width: 550,
        fly: true,
    };

    if (selectedServiceOrder === undefined) {
        return <Sidebar {...sidebarProperties} closed />;
    }

    const handleArchiveServiceOrder = runInBackgroundCallback(async () => {
        await archiveServiceOrder({ serviceOrderId: selectedServiceOrder.id })
            .unwrap()
            .then(() => {
                showSuccessNotification('outboundPortal.notification.archived.success');
                closeSidebar();
            })
            .catch(handleQueryError);
    });

    const checkFormValidity = (): boolean => {
        let isValid = true;
        const newFormErrors: SidebarFormErrors = {};
        selectedServiceOrder.vehicle.requestedAccessories.forEach((requestedAccessory: AccessoryType) => {
            const accessoryValue = completion.accessories[getObjectKeyForAccessory(requestedAccessory)];
            if (accessoryValue === undefined || accessoryValue < 0) {
                newFormErrors[requestedAccessory] = { type: 'invalidValue' };
                isValid = false;
            }
        });

        if (!isValidDate(completion.completedAt)) {
            newFormErrors.completedAt = { type: 'invalidDate' };
            isValid = false;
        } else {
            if (completion.completedAt > new Date()) {
                newFormErrors.completedAt = { type: 'dateInFuture' };
                isValid = false;
            } else if (
                completion.completedAt <
                dateMinusSeconds(selectedServiceOrder.orderIssuedAt, GRACE_PERIOD_IN_SECONDS_COMPLETION_DATE)
            ) {
                newFormErrors.completedAt = { type: 'completedAtBeforeOrderIssuedAt' };
                isValid = false;
            }
        }
        setFormErrors(newFormErrors);
        return isValid;
    };

    const handleCompleteServiceOrder = runInBackgroundCallback(async () => {
        if (checkFormValidity()) {
            clearFormErrors();
            const completeServiceOrderPayload = {
                serviceOrderCompletion: {
                    type: selectedServiceOrder.type,
                    vehicle: { ...selectedServiceOrder.vehicle, collectedAccessories: completion.accessories },
                    completedAt: completion.completedAt as Date,
                    changedAt: new Date(),
                    generalInformation: isEmpty(completion.generalInformation?.trim())
                        ? undefined
                        : completion.generalInformation?.trim(),
                    serviceOrderIdToBeCompleted: selectedServiceOrder.id,
                },
            };

            await completeServiceOrder(completeServiceOrderPayload)
                .unwrap()
                .then(() => {
                    showSuccessNotification('outboundPortal.notification.serviceOrder.completion.success');
                    closeSidebar();
                })
                .catch(handleQueryError);
        }
    });

    const title = (
        <div className="text-size-16">
            <span>{selectedServiceOrder.type} - </span>
            {getServiceNameByType(selectedServiceOrder.type)}
        </div>
    );

    return (
        <Sidebar
            {...sidebarProperties}
            title={title}
            titleClassName="padding-left-10"
            closed={false}
            onClose={closeSidebar}
            footer={
                <FooterButtons
                    serviceOrder={selectedServiceOrder}
                    handleArchiveServiceOrder={handleArchiveServiceOrder}
                    handleCompleteServiceOrder={handleCompleteServiceOrder}
                    onClose={closeSidebar}
                    isRequestPending={archiveRequest.isLoading || completionRequest.isLoading}
                />
            }
            footerClassName="margin-right-10"
        >
            <ServiceOrderSidebarHeader serviceOrder={selectedServiceOrder} />
            <ServiceOrderSidebarBody
                errors={formErrors}
                serviceOrder={selectedServiceOrder}
                setCompletionFormData={(newCompletion: ServiceOrderSidebarCompletion) => setCompletion(newCompletion)}
                completionFormData={completion}
            />
        </Sidebar>
    );
};
