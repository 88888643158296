import { FormattedMessage, useIntl } from 'react-intl';
import { FilterModalDatePicker } from '../../../sharedComponents/filter/FilterModal';
import moment, { Moment } from 'moment';
import { ServiceTypeFilter } from './ServiceTypeFilter';
import Multiselect from '@rio-cloud/rio-uikit/Multiselect';
import { createStatusOptions, STATUS_FILTER_PLACEHOLDER } from '../../../sharedComponents/filter/StatusFilter';
import { ServiceOrderStatus } from '../../ServiceOrders.types';
import React, { Dispatch, SetStateAction, useId } from 'react';
import { ServiceType } from '../../serviceType.types';
import { DateInput } from '../../../sharedComponents/dateHelper';

export type LocalModalFilters = {
    startDateFilter: DateInput;
    endDateFilter: DateInput;
    serviceTypeFilter: ServiceType[];
    statusFilter: ServiceOrderStatus[];
};

export const UNSET_FILTERS: LocalModalFilters = {
    startDateFilter: undefined,
    endDateFilter: undefined,
    serviceTypeFilter: [],
    statusFilter: [],
};

type ServiceOrderExportFilterModalContentProps = {
    localFilters: LocalModalFilters;
    setLocalFilters: Dispatch<SetStateAction<LocalModalFilters>>;
    hasStartDateTimeError: boolean;
    hasEndDateTimeError: boolean;
    hasTimeRangeOrderError: boolean;
};

type ServiceStatusFilterProps = {
    localFilters: LocalModalFilters;
    setLocalFilters: Dispatch<SetStateAction<LocalModalFilters>>;
};

const ServiceStatusFilter = ({ localFilters, setLocalFilters }: ServiceStatusFilterProps) => {
    const intl = useIntl();
    const id = useId();

    return (
        <>
            <label htmlFor={id}>
                <FormattedMessage id="outboundOrderBook.serviceOrders.export.filter.status" />
            </label>
            <Multiselect
                id={id}
                placeholder={intl.formatMessage({
                    id: STATUS_FILTER_PLACEHOLDER,
                })}
                options={createStatusOptions(Object.values(ServiceOrderStatus))}
                value={localFilters.statusFilter}
                onChange={(statusFilter) => {
                    setLocalFilters((prevState) => ({
                        ...prevState,
                        statusFilter: statusFilter as ServiceOrderStatus[],
                    }));
                }}
            />
        </>
    );
};

export const ServiceOrderExportModalContent = ({
    localFilters,
    setLocalFilters,
    hasStartDateTimeError,
    hasEndDateTimeError,
    hasTimeRangeOrderError,
}: ServiceOrderExportFilterModalContentProps) => {
    // TODO PORTALOUT-2874 consider renaming and moving to shared
    // maps moments to dates and '' to undefined
    const nextDateValue = (date: Moment | string | undefined) =>
        moment.isMoment(date) ? date.toDate() : date === '' ? undefined : date;
    const startDateId = useId();
    const endDateId = useId();

    return (
        <div className="margin-bottom-20">
            <div className="margin-bottom-10">
                <span>
                    <FormattedMessage id="outboundOrderBook.serviceOrders.export.description" />
                </span>
            </div>
            <label className="margin-bottom-10">
                <FormattedMessage id="outboundPortal.serviceOrders.table.toolbar.filter.orderIssuedDate" />
            </label>
            <div className="display-flex justify-content-start row">
                <div className="col-6 padding-right-5">
                    <FilterModalDatePicker
                        id={startDateId}
                        labelId="outboundPortal.common.table.from"
                        hasFormatError={hasStartDateTimeError}
                        hasTimeRangeOrderError={false}
                        value={localFilters.startDateFilter}
                        onChange={(date: Moment | string | undefined) =>
                            setLocalFilters((prevState) => ({
                                ...prevState,
                                startDateFilter: nextDateValue(date),
                            }))
                        }
                    />
                </div>
                <div className="col-6 padding-left-5">
                    <FilterModalDatePicker
                        id={endDateId}
                        labelId="outboundPortal.common.table.to"
                        hasFormatError={hasEndDateTimeError}
                        hasTimeRangeOrderError={hasTimeRangeOrderError}
                        value={localFilters.endDateFilter}
                        onChange={(date: Moment | string | undefined) =>
                            setLocalFilters((prevState) => ({
                                ...prevState,
                                endDateFilter: nextDateValue(date),
                            }))
                        }
                    />
                </div>
            </div>
            <hr className="margin-top-20 margin-bottom-20" />
            <div className="margin-bottom-20">
                <ServiceStatusFilter localFilters={localFilters} setLocalFilters={setLocalFilters} />
            </div>
            <div>
                <ServiceTypeFilter
                    value={localFilters.serviceTypeFilter}
                    onChange={(serviceTypeFilter) =>
                        setLocalFilters((prevState) => ({ ...prevState, serviceTypeFilter }))
                    }
                />
            </div>
        </div>
    );
};
