import classNames from 'classnames';
import { ReactElement } from 'react';
import { NoValue } from '../../sharedComponents/NoValue';
import { ServiceOrder, ServiceOrderCategory, ServiceOrderStatus } from '../ServiceOrders.types';
import { serviceOrderColumnDefinition, ServiceOrdersTableColumns } from './ServiceOrdersTableColumns';
import { AlertIcon, DateTime } from '../../sharedComponents/table/BaseTableComponents';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import {
    getSelectedServiceOrder,
    getServiceOrderCategory,
    serviceOrdersSlice,
} from '../../reducers/serviceOrdersSlice';
import { getRowAlertConfiguration } from './serviceOrderTableRowAlertStyling';
import { AlertConfig } from '../../sharedComponents/common.types';
import { ServiceOrderTableActionButton } from './ServiceOrderTableActionButton';
import { StatusLabel } from '../../sharedComponents/StatusLabel';
import { getServiceNameByType } from '../serviceOrderUtils';

export const TableDataContent = (props: {
    column: ServiceOrdersTableColumns;
    serviceOrder: ServiceOrder;
}): ReactElement => {
    const { serviceOrder } = props;
    switch (props.column) {
        case ServiceOrdersTableColumns.EXTERNAL_ID:
            return <span>{serviceOrder.externalOrderId}</span>;
        case ServiceOrdersTableColumns.STATUS:
            return <StatusLabel status={serviceOrder.status} />;
        case ServiceOrdersTableColumns.VIN:
            return (
                <strong>
                    {serviceOrder.vehicle.id.vin ?? serviceOrder.vehicle.id.productionNumber ?? <NoValue />}
                </strong>
            );
        case ServiceOrdersTableColumns.MANUFACTURER:
            return <span>{serviceOrder.vehicle.model?.manufacturer ?? <NoValue />}</span>;
        case ServiceOrdersTableColumns.MODEL:
            return serviceOrder.vehicle.model === undefined ? (
                <NoValue />
            ) : (
                <span>
                    <span className="rioglyph rioglyph-car text-size-18 padding-right-5" />
                    {serviceOrder.vehicle.model?.name}
                </span>
            );
        case ServiceOrdersTableColumns.EXECUTION_DATE:
            return serviceOrder.completedAt === undefined ? (
                <NoValue
                    messageId={
                        serviceOrder.status === ServiceOrderStatus.CANCELLED
                            ? undefined
                            : 'outboundPortal.serviceOrder.sidebar.executionDate.missing'
                    }
                />
            ) : (
                <DateTime dateTime={serviceOrder.completedAt} timeZone={serviceOrder.timeZone} />
            );
        case ServiceOrdersTableColumns.ORDER_ISSUE_DATE:
            return <DateTime dateTime={serviceOrder.orderIssuedAt} timeZone={serviceOrder.timeZone} />;
        case ServiceOrdersTableColumns.SERVICE_CODE:
            return <span>{serviceOrder.type}</span>;
        case ServiceOrdersTableColumns.SERVICE_NAME:
            return (
                <strong>
                    <span className="rioglyph rioglyph-component-custom text-size-18 padding-right-5" />
                    {getServiceNameByType(serviceOrder.type)}
                </strong>
            );
        default:
            throw new Error('exhaustive switch exception - table content');
    }
};

const TableRowData = (props: {
    column: ServiceOrdersTableColumns;
    serviceOrder: ServiceOrder;
    highlightConfig: AlertConfig | undefined;
}) => {
    const { column, serviceOrder } = props;
    const highlightConfig = props.highlightConfig !== undefined ? [props.highlightConfig] : [];

    switch (column) {
        case ServiceOrdersTableColumns.HIGHLIGHT:
            return (
                <td key={column}>
                    <AlertIcon rowConfig={highlightConfig} />
                </td>
            );
        case ServiceOrdersTableColumns.EXTERNAL_ID:
        case ServiceOrdersTableColumns.MODEL:
        case ServiceOrdersTableColumns.SERVICE_NAME:
            return (
                <td className="ellipsis-1" key={column}>
                    <TableDataContent column={column} serviceOrder={serviceOrder} />
                </td>
            );
        case ServiceOrdersTableColumns.STATUS:
            return (
                <td key={column}>
                    <TableDataContent column={column} serviceOrder={serviceOrder} />
                </td>
            );
        case ServiceOrdersTableColumns.ACTION_BUTTON:
            return (
                <td className="text-center" key={column}>
                    <ServiceOrderTableActionButton serviceOrder={serviceOrder} />
                </td>
            );
        default:
            return (
                <td key={column}>
                    <TableDataContent column={column} serviceOrder={serviceOrder} />
                </td>
            );
    }
};

export const ServiceOrderRow = (props: { serviceOrder: ServiceOrder }) => {
    const { id, status, hasUnacknowledgedExternalChanges } = props.serviceOrder;
    const selectedServiceOrder = useAppSelector(getSelectedServiceOrder);
    const tableCategory = useAppSelector(getServiceOrderCategory);
    const dispatch = useAppDispatch();
    const highlightConfig = getRowAlertConfiguration(status, hasUnacknowledgedExternalChanges);

    const isSelected = selectedServiceOrder?.id === id;

    const handleRowClick = () => {
        if (isSelected) {
            dispatch(serviceOrdersSlice.actions.deselectServiceOrder());
        } else {
            dispatch(serviceOrdersSlice.actions.selectServiceOrder(props.serviceOrder));
        }
    };

    return (
        <tr
            key={id}
            onClick={handleRowClick}
            className={classNames('cursor-pointer', isSelected && 'active', highlightConfig?.highlight)}
        >
            {serviceOrderColumnDefinition
                .filter(
                    (def) =>
                        tableCategory !== ServiceOrderCategory.ARCHIVE ||
                        def.column !== ServiceOrdersTableColumns.ACTION_BUTTON,
                )
                .map((def) => {
                    return (
                        <TableRowData
                            key={def.column}
                            column={def.column}
                            serviceOrder={props.serviceOrder}
                            highlightConfig={highlightConfig}
                        />
                    );
                })}
        </tr>
    );
};
